table,
thead,
tbody,
tfoot,
tr,
th,
td {
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  width: auto;
  height: auto;
  vertical-align: middle;
  text-align: center;
  -webkit-border-horizontal-spacing: 0;
  -webkit-border-vertical-spacing: 0;
}

.table {
  width: 100%;
  color: var(--app-gray-on-background-color);
  font-size: $fontXXXXSmall;

  &.user-profile {
    .table__body {
      tr {
        td {
          .cut-text-box {
            max-width: 20rem;
          }
        }
      }
    }
  }

  &__heading {
    font-weight: bold;

    & tr {
      &:first-child {
        padding-top: 2rem;
      }

      transition: 0.2s ease;

      & th {
        text-align: left;
        padding: 0 1.5rem;
        font-family: var(--app-font-primary);
        letter-spacing: $letterSpacing;
        font-style: normal;
        position: relative;
        font-weight: 600;
        font-size: var(--fontXXXSmall);
        color: var(--app-gray-on-background-color);

        &:last-child {
          width: 0;
          min-width: 0;
          text-align: left;
          white-space: nowrap;
        }

        &:first-child {
          padding-left: 0 !important;
        }

        .arrows {
          margin-left: 0.8rem;
          position: relative;
          top: 4px;
          font-size: 1.2rem;

          .fas {
            position: absolute;
            left: 0;
            font-size: 1.2rem;

            @media (max-width: 1280px) {
              font-size: 1.1rem;
            }

            &.fa-caret-up {
              visibility: hidden;
              bottom: 1rem;
            }

            &.fa-caret-down {
              visibility: hidden;
              top: 0;
            }

            &.fa-arrow-up,
            &.fa-arrow-down {
              bottom: 0.65rem;
              visibility: hidden;
              font-weight: 600;
              font-size: 1rem;
              color: var(--app-link-color);
            }
          }
        }

        &:hover {
          .arrows {
            .fas {
              &.fa-caret-up {
                visibility: visible;
              }

              &.fa-caret-down {
                visibility: visible;
              }
            }
          }
        }

        &.sortable-header {
          cursor: pointer;
        }

        &.sort-header-primary {
          color: var(--app-link-color);

          .arrows {
            color: var(--app-gray-on-background-table-row-color);

            &.fa-caret-up {
              color: var(--app-link-color);
            }

            &.fa-caret-down {
              color: var(--app-link-color);
            }
          }

          &:hover {
            .arrows {
              .fas {
                &.fa-arrow-down,
                &.fa-arrow-up {
                  visibility: hidden;
                }
              }
            }
          }
        }

        &.arrow-down {
          .arrows {
            .fas {
              &.fa-arrow-down {
                visibility: visible;
              }
            }
          }
        }

        &.arrow-up {
          .arrows {
            .fas {
              &.fa-arrow-up {
                visibility: visible;
              }
            }
          }
        }
      }
    }
  }

  &__body {
    box-sizing: border-box;

    td {
      text-align: left;
      padding: 0 1.5rem;
      max-height: 6.1rem;
      height: 6rem;
      font-weight: 400;

      &:last-child.align-left {
        text-align: left;
      }

      &:first-child {
        padding-left: 0 !important;
      }

      a:not(:first-child) {
        margin-left: 1.4rem;

        @media (max-width: 767px) {
          margin-left: 1.2rem;
        }
      }

      &.td-bold {
        font-weight: 600;
      }

      &.td-right {
        text-align: right;
      }
    }

    & tr {
      background-color: var(--app-gray-primary-color-20);
      transition: 0.3s ease;
      font-style: normal;
      font-size: 1.3rem;
      font-weight: normal;
      line-height: 1.6rem;
      color: var(--app-gray-on-background-color);
    }

    &.pointer {
      tr {
        cursor: pointer;

        &:hover {
          background: var(--app-gray-primary-color-40);
          transition: 0.3s ease;
        }
      }
    }
  }

  &.table-little {
    &__heading {
      & tr {
        & th {
          min-width: 0 !important;
          padding-right: 0 !important;

          &:first-child {
            padding-left: 0 !important;
          }
        }
      }
    }

    &__body {
      td {
        min-width: 0 !important;
        padding-right: 6.4rem !important;

        &:first-child {
          padding-left: 0 !important;
        }

        &:last-child {
          padding-right: 2rem !important;
        }
      }
    }
  }
}

.rtl {
  .container {
    .main {
      .table {
        .table__heading {
          tr {
            th {
              text-align: right;
            }
          }
        }

        .table__body {
          td {
            text-align: right;
          }
        }
      }
    }
  }
}

.container {
  .main {
    .table {
      .table__heading {
        tr {
          th {
            padding-top: 2.4rem;
            padding-bottom: 2.4rem;
            font-size: var(--fontXXXSmall);
            border-bottom: 1.5px solid var(--app-gray-primary-color-60);

            &:last-child {
              min-width: 0;
              width: 1px;
              text-align: left;
              padding-right: 3.2rem;
            }

            &:first-child {
              padding-left: 0.8rem !important;
            }

            &.clickable {
              cursor: pointer;
              white-space: nowrap;
            }
          }
        }
      }

      .table__body {
        tr {
          td {
            white-space: nowrap;
            border-bottom: 1.5px solid var(--app-gray-primary-color-60);

            &.wrap-normal {
              white-space: normal;
            }

            .drop-arrow {
              display: none;
            }

            .action-buttons {
              display: flex;
              align-items: center;
              max-height: 1.6rem;
              gap: 1.6rem;
            }

            &.mobile-version {
              display: none;
            }

            &:last-child {
              width: 0;
              min-width: 0;
              padding-right: 1.6rem !important;
            }

            &:first-child {
              padding-left: 0.8rem !important;
            }

            .cut-text-box {
              word-wrap: break-word;
              width: 100%;
              display: block;
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;
              font-style: normal;
              line-height: 1.8rem;
              max-width: 15rem;

              @media (max-width: 1191px) {
                max-width: 13rem;
              }

              @media (max-width: 891px) {
                max-width: 7rem;
              }
            }
          }
        }
      }

      &.table-little {
        .table__heading {
          & tr {
            & th {
              min-width: 0 !important;
            }
          }
        }

        .table__body {
          td {
            min-width: 0 !important;
            padding-right: 6.4rem !important;
          }
        }
      }

      &.table-without-search {
        .table__heading {
          tr {
            th {
              padding-top: 3.2rem;
            }
          }
        }
      }
    }
  }
}

@include screen(md) {
  .container {
    .main {
      .table.revenue {
        margin-top: 3rem;

        .table__body tr td {
          padding: 1.2rem 1rem !important;
        }

        .table__heading tr th {
          padding: 0.8rem 1rem !important;
        }
      }

      .table:not(.revenue) {
        font-size: 1.3rem;
        width: 100%;

        .table__heading {
          display: none;
        }

        &,
        tbody,
        td,
        tr {
          display: block;
        }

        .table__body {
          tr {
            padding: 1rem 0;
            text-align: -webkit-left;
            margin-bottom: 2rem;

            td {
              display: inline-block;
              width: 50%;
              padding: 1.3rem 2.5rem !important;
              border-top: 1px solid transparent;
              vertical-align: middle;
              white-space: nowrap;
              overflow: visible;
              text-overflow: ellipsis;

              &.td-with-icon {
                @media screen and (max-width: 993px) {
                  display: flex;
                }
              }

              @media (max-width: 375px) {
                padding: 1.3rem 1rem 1.3rem 1.8rem !important;
              }

              &::before {
                width: 50%;
                display: inline-block;
                color: var(--textColorPrimary);
                vertical-align: middle;
                font-family: var(--app-font-primary);
                letter-spacing: $letterSpacing;
                font-weight: 600;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                padding-right: 0.5rem;

                @media (max-width: 375px) {
                  line-height: 2.5rem;
                }
              }

              .cut-text-box {
                width: 50%;
                display: inline-block;
                vertical-align: middle;

                @media (max-width: 375px) {
                  width: 100%;
                  line-height: 2.5rem;
                }
              }
            }
          }

          td::before {
            content: attr(data-title);
          }

          .no-search-results {
            td::before {
              width: 0;
            }
          }
        }
      }

      .table:not(.revenue) {
        .table__body {
          tr {
            td {
              max-width: calc(100vw - 4rem);
              width: 100%;
              padding: 1.3rem 1rem !important;

              @include screen(xs) {
                width: 100%;
              }

              &:first-child {
                padding-left: 1rem;
              }
            }
          }
        }
      }
    }
  }
}
