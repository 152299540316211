@import 'variables';

/**
LARGE CONTAINER
 */
.select-container-large {
  height: auto;
  box-shadow: inset 0 1px 1.6px rgb(0 0 0 / 7%);
  background-color: #fff;
  position: relative;
  margin-top: 6px;

  &__tip {
    position: absolute;
    top: 27px;
    left: 12px;
    z-index: 1001;
    pointer-events: none;
    font-size: $fontXXXSmall;
    color: var(--textColorPrimary);
  }
}

/**
SIMPLE, SEARCHABLE CONTAINER, MULTIPLE CONTAINER, AUTHORIZATION
 */
.select-container-simple,
.select-container-searchable,
.select-container-multiple {
  min-height: 3.6rem;
  height: auto;
  background-color: #fff;
  cursor: pointer;
}

/**
SEARCHABLE CONTAINER
 */
.select-container-searchable {
  position: relative;

  .ng-select .ng-select-container .ng-value-container {
    padding-left: 1.2rem;
  }

  .loupe {
    position: absolute;
    top: calc(50% - 10px);
    right: 30px;
    width: 20px;
    height: 20px;
    background: $backgroundColorInput 50% url('/assets/icons/v2/ic_search.svg') no-repeat;
    background-size: 16px 16px;
    pointer-events: none;
  }

  &.pending {
    .loupe {
      background-color: #f5f5f5;
    }
  }
}

/**
sign up select CONTAINER
 */
.ng-select.sign-up {
  .ng-select-container {
    border: none;
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
}

/**
SELECT IN GENERAL
 */
.ng-select.large {
  border-radius: 6px;

  .ng-select-container {
    color: var(--textColorPrimary);

    .ng-value-container {
      padding-left: 0;
    }
  }
}

.ng-select.simple {
  margin-top: 6px;
}

.ng-select.with-icon .ng-select-container {
  padding-left: 40px;
}

.ng-select.authorization {
  background: var(--backgroundInput);
  box-shadow: inset 0 1px 2px rgb(0 0 0 / 3%);
  border: unset;
  border-radius: 4px;
  font-style: normal;
  font-weight: normal;
  font-size: var(--fontXSmall);
  line-height: 19px;
}

.simple.ng-select.ng-select-single .ng-select-container .ng-value-container {
  .ng-input > input {
    font-size: 1.3rem;
    font-family: var(--app-font-primary);
  }

  .ng-value {
    font-size: 1.6rem;
  }
}

.authorization.ng-select.ng-select-single .ng-select-container .ng-value-container {
  .ng-value {
    font-style: normal;
    font-weight: normal;
    font-size: var(--fontXSmall) !important;
    line-height: 19px;
  }
}

.ng-select.large .ng-select-container:hover,
.ng-select.simple .ng-select-container:hover,
.ng-select.searchable .ng-select-container:hover,
.ng-select.multiple .ng-select-container:hover,
.ng-select.multiple .ng-select-container:hover,
.ng-select.sign-up__select .ng-select-container:hover {
  box-shadow: none;
}

.ng-select.large.ng-select-focused:not(.ng-select-opened) .ng-select-container,
.ng-select.simple.ng-select-focused:not(.ng-select-opened) .ng-select-container,
.ng-select.multiple.ng-select-focused:not(.ng-select-opened) .ng-select-container,
.ng-select.sign-up.ng-select-focused:not(.ng-select-opened) .ng-select-container,
.ng-select.sign-up.ng-select-focused:not(.ng-select-opened) .ng-select-container,
.ng-select.sign-up__select.ng-select-focused:not(.ng-select-opened) .ng-select-container {
  box-shadow: none;
  border-color: var(--borderColorLine) !important;
}

.ng-select.sign-up.ng-select-focused .ng-select-container {
  border-bottom: 1px solid var(--app-color-main) !important;
}

.ng-select.searchable.ng-select-disabled .ng-select-container,
.ng-select.ng-select-disabled .ng-select-container,
.ng-select.simple.ng-select-disabled .ng-select-container {
  cursor: not-allowed;

  .loupe {
    background-color: #f5f5f5;
  }

  .ng-arrow-wrapper {
    display: none;
  }
}

.account-select-with-popup .ng-select.simple .ng-select-container {
  cursor: pointer !important;

  .ng-arrow-wrapper {
    display: block;
  }
}

.account-select-with-popup.not-allowed .ng-select.simple .ng-select-container {
  cursor: not-allowed !important;

  .ng-arrow-wrapper {
    display: block;
  }
}

.ng-select.large.ng-select-disabled .ng-select-container {
  background-color: unset;
  cursor: unset !important;

  //
  //this arrow will use for table ng-select for filter data in table
  //
  //.ng-arrow-wrapper {
  //  display: none;
  //}
}

/**
SELECT-CONTAINER > NG-SELECT > NG-SELECT-CONTAINER
 */
.ng-select.simple.ng-select-single .ng-select-container,
.ng-select.searchable.ng-select-single .ng-select-container {
  border: 0 solid black;
  border-radius: 6px;
  box-shadow: none;
  cursor: pointer;
}

.ng-select.simple.ng-select-single .ng-select-container {
  height: 5.4rem;
  border: none;
  border-radius: 1rem !important;
  background: $backgroundColorTRPrimary;
}

.ng-select.ng-select-disabled.simple.ng-select-single .ng-select-container {
  height: 5.4rem;
  border: none;
  border-radius: 1rem !important;

  //background-color: var(--app-color-gray-100);
}

.ng-select.simple.ng-select-multiple .ng-select-container {
  height: 5.4rem;
  border: none;
  border-radius: 1rem !important;
  background: $backgroundColorTRPrimary;
}

.ng-select.sign-up.ng-select-single .ng-select-container {
  height: 47px;
  border: none;
  box-shadow: none;
  cursor: pointer;
}

.ng-select.large.ng-select-single .ng-select-container {
  height: 34px;
  border: 0.8px solid var(--borderColorLine);
  border-radius: 3.2px;
  box-shadow: inset 0 1px 1.6px rgb(0 0 0 / 7%);
  cursor: pointer;
  padding-left: 1.2rem;

  /**
  VALUE IS SELECTED
   */
  &.ng-has-value {
    height: 100%;

    .ng-value-container {
      display: flex;
      flex-direction: column;
      align-items: baseline;
      justify-content: flex-start;
      background: var(--app-color-white);

      .top-hint,
      .bottom-hint {
        color: $textColorDim;
        font-size: $fontXSmall;
      }

      .select-value {
        font-size: var(--fontXXXXSmall);
        color: var(--colorDarkGrey);
        line-height: 16px;
        font-weight: normal;
      }

      .bottom-hint {
        font-size: var(--fontXXXXSmall);
        color: var(--selectColorPlaceholder);
        line-height: 16px;
        font-weight: normal;
        margin-top: 6px;
      }
    }
  }

  .ng-arrow-wrapper {
    padding-right: 10px;
  }
}

.ng-dropdown-panel.large.ng-select-bottom,
.ng-dropdown-panel.large.ng-select-top {
  left: 0;
}

//INPUT FIELD

.ng-select.large .ng-select-container .ng-value-container .ng-input {
  position: unset;
  left: unset;
  display: none;
}

.ng-select.searchable .ng-select-container .ng-value-container .ng-input {
  top: 10px;
  padding-left: 0 !important;

  input {
    font-size: 14px;
  }
}

.ng-select.simple.searchable .ng-select-container .ng-value-container {
  .ng-input {
    top: 0;
  }

  line-height: 4.4rem;
}

/**
PLACEHOLDER ('SELECT CURRENCY, SELECT ACCOUNT, etc.')
 */
.ng-select.large .ng-select-container .ng-value-container .ng-placeholder {
  font-size: var(--fontXXXXSmall);
  font-family: var(--app-font-primary);
  color: var(--selectColorPlaceholder);
  letter-spacing: $letterSpacing;
  font-weight: normal;
}

.one-line.ng-select.large .ng-select-container .ng-value-container .ng-placeholder {
  padding-top: 0;
}

/**
DROPDOWN LIST
 */
.ng-select.simple .ng-dropdown-panel-items .ng-option {
  &.ng-option-marked {
    background-color: var(--colorCheckboxHover);
  }

  &.ng-option-selected {
    background-color: $backgroundColorContent;

    span {
      font-weight: 400;
    }
  }

  overflow-wrap: break-word !important;
  white-space: unset !important;
  font-style: normal;
  font-weight: normal;
  font-size: var(--fontXXXXSmall);
  line-height: 16px;

  &:first-child {
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
  }

  &:last-child {
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
  }
}

.ng-dropdown-panel.large .ng-dropdown-panel-items .ng-option {
  height: 9rem;
  padding-left: 1.2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .select-value {
    font-size: $fontXXXSmall;
  }

  .bottom-hint {
    color: $textColorDim;
    font-size: $fontXXXSmall;
  }

  &.ng-option-marked,
  &.ng-option-selected {
    background-color: $backgroundColorMessageMenu;
  }
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
  background-color: #f5f5f5;
  color: #5b5b5b !important;
}

/*****************************************  SIMPLE  ************************************************/

.ng-select {
  &.simple {
    .ng-select-container {
      background: var(--app-gray-primary-color-40);

      .ng-value-container {
        padding-left: 1.2rem;
        font-size: 1.6rem;

        .ng-placeholder {
          font-weight: 400;
          font-size: 1.6rem;
          line-height: 1.6rem;
          color: var(--app-gray-primary-color-80);
        }

        .ng-value {
          font-size: 1.6rem;

          .ng-value-label {
            color: var(--app-gray-on-background-table-row-color);
          }

          .bottom-hint {
            color: var(--app-gray-primary-color-80);
          }
        }
      }

      &.ng-has-value + .ng-dropdown-panel {
        .ng-dropdown-panel-items {
          .ng-option {
            background: var(--app-color-white);

            &.ng-option-marked,
            &.ng-option-selected {
              background-color: var(--app-gray-primary-color-40);
            }
          }
        }
      }
    }

    .ng-arrow-wrapper {
      height: 1rem;
      width: 1rem;
      top: 0.1rem;
      background: url('/assets/icons/v2/ic_select-arrow.svg') no-repeat center;
      margin-right: 1.6rem;

      .ng-arrow {
        display: none;
      }
    }

    &.ng-select-opened {
      .ng-arrow-wrapper {
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg);
      }
    }

    .ng-dropdown-panel {
      .scroll-host {
        border-radius: 1rem;
      }

      .ng-dropdown-panel-items {
        .ng-option {
          padding-left: 2.4rem;
          padding-top: 1.8rem;
          font-weight: 400;
          font-size: 1.4rem;
          line-height: 2rem;
          color: var(--app-color-gray-900);
          border: 1px solid transparent;
          background: var(--app-gray-primary-color-40);
          height: 5.7rem;

          &.ng-option-marked {
            background-color: var(--app-gray-primary-color-60);
          }

          &.ng-option-selected {
            background-color: var(--app-gray-primary-color-60);

            span {
              font-weight: 400;
            }
          }

          .option-with-select {
            display: flex;
            align-items: center;

            .icon-select-wrapper {
              width: 1.6rem;
              height: 1.6rem;
              display: flex;
              justify-content: center;
              align-items: center;
              border-radius: 0.4rem;
              border: 1px solid var(--app-color-primary-600);
              margin-right: 0.9rem;
            }
          }
        }
      }
    }

    .ng-arrow-wrapper {
      padding-right: 10px;
    }
  }

  &.ng-select-opened {
    .ng-select-container {

      .ng-arrow {
        top: -2px;
        border-color: transparent transparent var(--app-gray-primary-color-80) !important;
        border-width: 0 4px 5px !important;
      }
    }
  }

  &.large {
    margin-top: auto;

    .ng-select-container {
      .ng-value-container {
        height: auto;

        .ng-value {
          display: flex;
          flex-direction: column;
        }
      }
    }
  }
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container {
  flex-wrap: nowrap !important;
  max-width: 100%;
  overflow: hidden;
  display: inline;
  padding-right: 10px;
  text-overflow: ellipsis;
  padding-top: 2.2rem !important;
  padding-left: 1.2rem !important;

  .ng-placeholder {
    position: relative !important;
    margin-bottom: -0.8rem !important;
  }

  .ng-value {
    white-space: nowrap;
    display: inline;
    text-overflow: ellipsis;
    color: var(--app-gray-on-background-table-row-color) !important;
    font-size: 1.6rem !important;
    background-color: unset !important;
  }
}

/************************************  SIGN-UP  ******************************************/

.ng-select.sign-up .ng-dropdown-panel {
  border: none !important;
  background: #fff !important;
  box-shadow: 0 10px 32px rgb(0 0 0 / 5%) !important;
  border-radius: 2px !important;
}

.ng-select.sign-up .ng-dropdown-panel .ng-dropdown-panel-items {
  margin: 0 !important;
}

.ng-select.sign-up .ng-dropdown-panel .ng-option:hover {
  background: #f7f6f6;
}

.ng-select.sign-up .ng-dropdown-panel .ng-option.ng-option-marked {
  background: #f7f6f6;
}

/************************************  SORT-SELECT  ******************************************/

.ng-select.sort-select .ng-select-container,
.ng-select.sort-select.ng-select-opened > .ng-select-container {
  background: transparent !important;
  border: none;
  box-shadow: unset !important;
  padding: 0;
  top: -5px;
  color: var(--textColorPrimary);
  cursor: pointer;
}

.ng-select.sort-select .ng-select-container .ng-value-container .ng-placeholder {
  color: var(--selectColorPlaceholder);
}

.ng-select.sort-select > .ng-select-container .ng-arrow {
  border-color: $backgroundColorAside transparent transparent;
}

.ng-select.ng-select-single.sort-select .ng-select-container .ng-value-container .ng-input {
  top: 5px !important;
  left: 0 !important;
}

.ng-select.sort-select .ng-dropdown-panel.ng-select-bottom {
  margin-top: 5px;
}

.search-container__select.sort-select-container {
  .ng-select-container {
    .ng-value-container {
      padding-left: 1.2rem;

      @media (max-width: 1191px) {
        padding-left: 10px;
      }
    }

    .ng-arrow-wrapper {
      @media (max-width: 1191px) {
        padding-right: 3px;
      }
    }
  }

  .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
    color: var(--textColorPrimary);
    padding: 4px 12px;
    overflow-wrap: break-word !important;
    white-space: unset !important;

    &.ng-option-marked {
      background-color: $backgroundColorContent;
    }

    &:hover {
      background: $backgroundColorContent;
    }

    @media (max-width: 991px) {
      padding: 4px 10px !important;
    }

    &.ng-option-selected {
      background: $backgroundColorMessageMenu;
    }
  }
}

.transaction-log-status-header {
  .search-container__select.sort-select-container {
    margin-bottom: 12px;

    .ng-select-container {
      .ng-value-container {
        padding-left: 0;
      }
    }
  }
}

/**************************  Lock  ************************************************************************/

.select-container.native-select.lock-field::after {
  content: '';
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  position: absolute;
  background: #cccbcb;
  opacity: 0.2;
}

.ng-dropdown-panel.ng-select-bottom {
  top: 100% !important;
  box-sizing: border-box;
  border-radius: 1rem !important;
  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);
  border: none;
  margin-top: 0.8rem !important;
}

.ng-dropdown-panel.ng-select-top {
  bottom: 110% !important;
  border-top-right-radius: 0 !important;
  border-top-left-radius: 0 !important;
  border-color: #ddd !important;
  margin-top: 0 !important;
  left: 0;
}

.ng-select.ng-select-opened > .ng-select-container .ng-arrow {
  border-color: #999 transparent transparent;
  border-width: 0 4px 5px !important;
}

/************************** MULTIPLE ******************************/
.select-container-multiple {
  .ng-select.multiple {
    .ng-select-container {
      border: 0.8px solid var(--borderColorLine);
      border-radius: 3.2px;
      box-shadow: inset 0 1px 1.6px rgb(0 0 0 / 7%);
      min-height: 5.4rem;
      height: fit-content;
      display: flex;
      justify-content: space-between;

      .ng-arrow-wrapper {
        padding-right: 1rem;
      }

      &.ng-has-value {
        .ng-value-container {
          .ng-input {
            margin-top: 0;
          }
        }
      }

      .ng-value-container {
        padding: 0.5rem 1.2rem;
        max-width: 85%;
        display: flex;
        flex-direction: row;
        padding-top: 0 !important;
        flex-wrap: wrap !important;

        .ng-placeholder {
          z-index: unset;
          top: 1rem;
          padding: 0;
          font-style: normal;
          font-weight: normal;
          font-size: var(--fontXXXXSmall);
          line-height: 1.6rem;
          position: absolute !important;
        }

        .ng-input {
          padding: 0;
          margin-top: 7px;

          input {
            font-size: var(--fontXXXXSmall);
          }
        }

        .ng-value {
          margin: 0 7px 0 0;
          border: none;
          background: none;
          display: flex;
          flex-direction: row-reverse;
          background: $backgroundColorContent;
          border-radius: 6px;
          max-width: 100%;

          .ng-value-icon,
          .ng-value-label {
            padding: 2px 8px;
            max-width: 100%;
            overflow: hidden;
          }

          .ng-value-icon.left {
            border-right: unset;
            border-left: 2px solid #fff;
            border-radius: 0 6px 6px 0;
            line-height: 1.2;
          }

          .ng-value-icon.left:hover {
            background: $backgroundColorDim;
          }
        }
      }
    }
  }

  .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
    padding-left: 1.2rem;
    overflow-wrap: break-word !important;
    white-space: unset !important;

    &:hover {
      background-color: $backgroundColorContent;
    }

    &.ng-option-selected {
      background-color: $backgroundColorMessageMenu;
    }

    &.ng-option-marked {
      background-color: $backgroundColorContent;
    }
  }
}

.form .ng-select.ng-select-single:not(.large) .ng-select-container .ng-value-container,
.form .ng-select.ng-select-single:not(.large) .ng-select-container .ng-value-container .ng-value {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-style: normal;
  font-weight: normal;
  font-size: var(--fontXSmall);
  line-height: 24px;
  color: var(--app-color-gray-700);
}

.set-height.ng-select .ng-select-container .ng-value-container {
  min-height: 22px;
}

.form .ng-select.ng-select-single:not(.large) .ng-select-container .ng-value-container .ng-placeholder {
  font-style: normal;
  font-weight: normal;
  font-size: var(--fontXXXXSmall);
  line-height: 16px;
  color: var(--selectColorPlaceholder);
}

.form .ng-select.simple:not(.searchable) .ng-select-container .ng-value-container .ng-placeholder {
  color: var(--app-color-gray-400);
  font-size: 1.6rem;
}

/********************************  input  *****************************************/

.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
  top: 0;
  left: 10px !important;
  color: #5b5b5b;

  & > input {
    font-size: $fontXXXSmall;
  }
}

.ng-select.ng-select-single.simple .ng-select-container .ng-value-container .ng-input {
  top: 0;
  display: flex;
  height: 100%;
  align-items: center;
  padding-left: 0 !important;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder {
  top: unset !important;
  color: var();
}

/****************************************  checkbox-select  *******************************************/

.checkbox-select .ng-select.simple {
  .ng-select-container {
    height: 3.6rem;
    cursor: pointer;

    .ng-value-container {
      padding-left: 1rem;

      .ng-value {
        background: $backgroundColorMessageMenu;
        border: 1px solid $borderColorDefault;
        border-radius: 3px;

        .ng-value-icon.left {
          border-right: 1px solid $borderColorDefault;

          &:hover {
            background: $backgroundColorAsideHover;
          }
        }
      }
    }

    .ng-arrow-wrapper {
      padding-bottom: 2px;
    }

    .ng-value-container {
      .ng-placeholder {
        top: 12px;
        padding-left: 10px;
      }

      .ng-input {
        padding-bottom: 7px;
        padding-left: 10px;

        & > input {
          font-size: var(--fontXXXXSmall);
        }
      }
    }
  }

  .ng-dropdown-panel.simple {
    max-height: 180px;
    overflow-y: auto;
  }
}

/*******************************  user-select  ***************************************/

.user-select.simple.ng-select .ng-value-container {
  padding-left: 46px;
}

.user-select.simple.ng-select .ng-select-container .ng-value-container .ng-input {
  left: 36px !important;
}

.user-select.simple.ng-select .ng-select-container {
  background: 16px 50% url('/assets/icons/guy-transparent.svg') no-repeat;
  background-size: 14px 16px;
}

.native-select.user-select {
  background: unset;
}

/**********************************  page-select  *************************************/

.page-select-container {
  .page-select.ng-select {
    height: 3.2rem;

    .ng-select-container {
      height: 3.2rem;
      border-color: unset !important;
      box-shadow: unset !important;
      border: unset;
      background: unset;
      outline: none;
      min-height: 3.2rem;

      &:hover {
        box-shadow: unset;
      }

      .ng-value-container {
        padding-left: 1rem;
        margin-right: 0.4rem;
      }

      .ng-arrow-wrapper {
        height: 1rem;
        width: 1rem;
        top: 0.1rem;
        background: url('/assets/icons/v2/ic_arrow-down.svg') no-repeat center;
        margin-right: 0.8rem;

        .ng-arrow {
          display: none;
        }
      }

      .ng-value {
        color: var(--app-gray-on-background-table-row-color);
        font-weight: 600;
        font-size: 1.2rem;
        line-height: 1.4rem;
      }
    }

    &.ng-select-opened {
      .ng-select-container {
        .ng-arrow-wrapper {
          top: 0;
          transform: rotate(180deg);
        }
      }
    }
  }

  .ng-dropdown-panel {
    border: none;
    background: inherit;
    box-shadow: none;

    .ng-dropdown-panel-items {
      background: #ffffff;

      /* Dropdown/shadow opened */
      padding: 0.8rem 0;
      bottom: 0.4rem;
      box-shadow: 0 2px 8px rgba(37, 40, 43, 0.12);
      border-radius: 8px;

      .ng-option {
        min-height: 3rem;
        text-align: center;
        color: var(--app-gray-primary-color-100);
        font-weight: 400;
        font-size: 1.3rem;
        line-height: 1.8rem;

        &:hover {
          color: var(--app-gray-on-background-table-row-color);
          font-weight: 600;
          background-color: var(--app-gray-primary-color-30) !important;
        }

        &.ng-option-selected {
          color: var(--app-gray-on-background-table-row-color);
          background-color: var(--app-gray-primary-color-30) !important;
          font-size: 1.2rem;
        }

        &.ng-option-marked {
          background-color: var(--app-gray-primary-color-30) !important;
        }
      }
    }
  }
}

@media (min-width: 992px) {
  .search-container__select.sort-select-container.first-select .ng-select-container .ng-value-container {
    padding-left: unset;

    .ng-input {
      padding-left: unset;
    }
  }
}

.max-width-300-percents {
  .ng-dropdown-panel {
    max-width: 300% !important;
    width: auto !important;
  }
}

.ng-select .ng-arrow-wrapper .ng-arrow {
  border-color: var(--textColorPrimary) transparent transparent !important;
}

.input-header {
  color: var(--app-color-gray-700);
  font-size: 1.4rem;
  font-weight: 500;
  margin-bottom: 1.2rem;

  &__tooltip {
    display: inline-block;
    position: relative;
  }
}
