// DEPRECATED WAY $variable. Use --variable
// color
$textColorDim: #b6b6b6;
$textColorMedium: #a3a3a3;
$textColorError: #c94b4b;
$textColorPlaceholder: #b6b6b6;
$textColorSuccess: #7cdf8b;
$textColorPending: #fed603;
$textColorWaiting: #8098a3;
$textColorDark: #4a4a4a;
$textColorMainDark: #484848;
$textColorGray: #9b9b9b;
$borderColorAside: #7c7c7c;
$backgroundColorAsideHover: rgb(0 0 0 / 6%);
$borderColorDim: #e3e3e3;
$borderColorError: #c94b4b;
$borderColorSuccess: #94c817;
$borderColorMain: #0d42fb;
$borderColorFilter: #dbdcdb;
$backgroundColorAside: #5b5b5b;
$backgroundColorContent: #f5f5f5;
$backgroundColorDim: #e3e3e3;
$backgroundColorHeader: #fff;
$backgroundColorInput: #fff;
$backgroundColorSection: #fff;
$backgroundColorSortBar: #eee;
$backgroundColorTableHeading: #dbdcdb;
$backgroundColorTRPrimary: #f9f9f9;
$backgroundColorTRSecondary: #fff;
$backgroundColorPagination: #fff;
$backgroundColorPaginationActive: #7a7a7a;
$buttonHover: #90c710;
$bottomLineColor: #d8d8d8;
$backgroundColorCircleMenu: #ccc;
$backgroundButtonInInput: #f1f1f1;
$btnColor: #e9e9e9;
$btnColorHover: #f5f5f5;
$backgroundColorMessageMenu: #fafafa;
$btnbgColor: #fafafa;
$iconsColor: #bebebe;
$inputLabelColor: #5b5b5b;
$checkboxTitleColor: #5b5b5b;
$settingsIconColor: #dadada;

// font
$fontXXXXSmall: 1.3rem;
$fontXXXSmall: 1.4rem;
$fontXXSmall: 1.5rem;
$fontXSmall: 1.6rem;
$fontSmall: 1.7rem;
$fontMedium: 1.8rem;
$fontLarge: 2rem;
$fontXLarge: 2.2rem;
$fontXXLarge: 2.4rem;
$fontXXXLarge: 2.8rem;

//status-colors
$pendingColorStatus: #a5aab7;
$waitingColorStatus: #f79009;
$successColorStatus: #05a989;
$errorColorStatus: #f61a1a;
$mainColorStatus: #0d42fb;
$letterSpacing: 0.03em;
$letterSpacingTitle: 0.03em;

// screen size
$mobileSize: 767px;
$tabletMin: 991px;
$tabletMid: 1191px;
$desktopMin: 1280px;

//Velmie Wallet Color
$backgroundColorSignUp: #f7f6f6;
$textColorLabel: #c4c4c4;
$textColorContent: #999;
$borderColorDefault: #f7f6f6;
$borderColorLine: #e0e0e0;
$md-checkbox-border-color: #cfcfcf;
$md-checkbox-border-color-disabled: rgb(0 0 0 / 26%);
$md-checkbox-checked-color-disabled: rgb(0 0 0 / 26%);

//checkbox setting
$md-checkbox-size: 1.1em;
$md-checkbox-padding: 0.25em;
$md-checkbox-border-width: 1px;
$md-checkbox-border-radius: 0.125em;
$md-checkmark-width: 0.125em;
$md-checkmark-color: #fff;
$md-checkbox-label-padding: 0.75em;

:root {
  //fonts
  --app-font-primary: 'Accord Alternate';

  //aside menu settings
  --backgroundColorAside: #2d3349;
  --backgroundColorAsideSelected: rgb(255 255 255 / 10%);
  --backgroundColorAsideHovered: rgb(255 255 255 / 10%);
  --colorAside: #fff;
  --errorColor: #e54141;
  --disableColorButtom: #b1b1b3;
  --colorDarkGrey: #333;
  --colorSubtitleGrey: #999;
  --colorSubtitleBlack: #333;
  --colorBlack: #000;
  --mediumDarkGrey: #484848;
  --toggleUnchecked: #cfcfcf;
  --lightGrey: #999;
  --whiteSmoke: #f7f6f6;
  --colorCheckboxHover: #f6f6f6;
  --textColorPrimary: #c4c4c4;
  --borderColorLine: #e0e0e0;
  --borderBottomColorLine: #eee;
  --selectColorPlaceholder: #c4c4c4;
  --textColorMainDark: #484848;
  --textcolorMessage: #666;
  --defaultColorIcon: #c4c4c4;
  --colorIconTransaction: #7493ff;
  --radioButtonColor: #7493ff;
  --tabsBottomBorderColor: #eee;
  --accordionBackground: rgb(246 246 246 / 50%);
  --chekboxBorderBottom: #eee;

  //background
  --backgroundInput: #f7f6f6;
  --backgroundColorMessageMenu: #eee;
  --backgroundProgressTrack: #eee;
  --backgroundProgressStepPass: #cce8d0;
  --backgroundColorError: #e54141;

  //size
  --formWidth: 32.7rem;
  --fieldContainerMargin: 0 0 2.4rem;
  --formMargin: 8rem 1rem 8rem !important;
  --app-kyc-form-width: 67.2rem;

  //font-size
  --fontXXXXXSmall: 1.1rem;
  --fontXXXXSmall: 1.3rem;
  --fontXXXSmall: 1.4rem;
  --fontXXSmall: 1.5rem;
  --fontXSmall: 1.6rem;
  --fontSmall: 1.7rem;
  --fontMedium: 1.8rem;
  --fontLarge: 2rem;
  --fontXLarge: 2.2rem;
  --fontXXLarge: 2.4rem;
  --fontXXXLarge: 2.8rem;
  --fontXXXXLarge: 3rem;

  //DatePicker
  --fontSizeDatepickerTitle: 1.7rem;
  --fontSizeDatepickerDay: 1.4rem;
  --fontSizeDatepickerDayName: 1.2rem;
  --colorDatepickerTitle: #333;
  --colorDatepickerDayName: #333;
  --colorDatepickerDay: #333;
  --colorDatepickerDayToday: #333;
  --colorDatepickerDaySelected: #fff;
  --colorDatepickerDisabled: #c4c4c4;
  --backgroundColorDateickerDayToday: #eef2ff;
  --backgroundColorDateickerDaySelected: #229932;
  --backgroundColorDateickerYearSelected: #eef2ff;

  // paddings
  --app-content-horizontal-padding: 5%;

  //app colors
  --app-color-main: var(--app-color-primary-600);
  --app-link-color: #1d68af;
  --app-error-color: #f61a1a;
  --app-success-color: #05a989;
  --app-success-extra-light-color: #30bfa3;
  --app-color-white: #ffffff;

  //primary colors
  --app-color-primary-25: #f6fbff;
  --app-color-primary-50: #f0f7fe;
  --app-color-primary-100: #e7f2fd;
  --app-color-primary-200: #c6e3fe;
  --app-color-primary-300: #9cc9f2;
  --app-color-primary-600: #1d68af;
  --app-color-primary-700: #084c8d;

  //gray colors
  --app-color-gray-50: #f9f9f9;
  --app-color-gray-100: #eeeeee;
  --app-color-gray-200: #dddddd;
  --app-color-gray-300: #b3b3b3;
  --app-color-gray-400: #999999;
  --app-color-gray-500: #777777;
  --app-color-gray-700: #333333;
  --app-color-gray-800: #222222;
  --app-color-gray-900: #131313;

  //success colors
  --app-color-success-25: #f4fffa;
  --app-color-success-400: #2cda90;
  --app-color-success-500: #1cb675;

  //warning colors
  --app-color-warning-25: #fffcf5;

  //error colors
  --app-color-error-50: #fff5f5;
  --app-color-error-200: #fecdca;
  --app-color-error-300: #fda29b;
  --app-color-error-500: #f04438;
  --app-color-error-600: #d92d20;
  --app-color-error-700: #b42318;
  --app-color-error-800: #912018;

  //old gray colors
  --app-gray-primary-color-100: #81889a;
  --app-gray-primary-color-90: #8890a4;
  --app-gray-primary-color-80: #a5aab7;
  --app-gray-primary-color-70: #e3e5e8;
  --app-gray-primary-color-60: #eeeeef;
  --app-gray-primary-color-50: #dee1e9;
  --app-gray-primary-color-40: #f3f3f3;
  --app-gray-primary-color-30: #f6f6f6;
  --app-gray-primary-color-20: #fafafa;
  --app-gray-on-primary-color: #ffffff;
  --app-gray-background-color: #ffffff;
  --app-gray-on-background-color: #1f1e1d;
  --app-gray-on-background-table-row-color: #1f1e1d;
  --app-gray-primary-light-color: #1452f5;
}

:root[theme='dark'] {
}
