@import 'variables';

.form {
  @media (max-width: 991px) {
    .select-container-large {
      width: 100%;
    }

    .row .two-in-row__input-field,
    .row .two-in-row {
      max-width: unset !important;
      display: block !important;

      .account-select + .account-select {
        margin-top: 24px;
        display: block;
      }
    }
  }

  .nav-menu {
    margin-top: 40px;
  }

  .cancel-btn {
    font-size: 14px !important;
    transition: 0.2s ease;

    &:hover {
      color: #5b5b5b !important;
      transition: 0.2s ease;
    }
  }

  .form__row {
    .checkbox-container {
      & + .checkbox-text {
        position: relative;
        top: 1px;
      }
    }

    margin-top: 24px !important;

    &.title {
      flex-flow: row wrap;
      align-items: center;
      margin-top: 40px !important;
      margin-bottom: 4px;
      font-size: 16px !important;
      font-weight: 600;
    }
  }

  .row {
    ::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      font-weight: 300;
    }

    ::-moz-placeholder {
      /* Firefox 19+ */
      font-weight: 300;
    }

    :-ms-input-placeholder {
      /* IE 10+ */
      font-weight: 300;
    }

    :-moz-placeholder {
      /* Firefox 18- */
      font-weight: 300;
    }

    .two-in-row__input-field,
    .two-in-row {
      .native-input.amount {
        padding-left: 13px;
        font-weight: 400;
        letter-spacing: -0.2px;
        font-size: 1.6rem;
        padding-top: 12px;

        @-moz-document url-prefix() {
          padding-top: 13px;
        }

        &.with-currency {
          padding-left: 60px;
        }
      }
    }

    .two-in-row__tip {
      padding-top: 0 !important;
      line-height: 4.4rem;
      padding-left: 2.7rem;

      &.no-left-padding {
        padding-left: 0;
      }

      @media (max-width: 991px) {
        padding-left: 0;
        line-height: normal;
        padding-top: 2.4rem;

        &.mobile-margin {
          margin-top: 16px;
        }
      }
    }

    .two-in-row {
      .account-select {
        @media (max-width: 991px) {
          max-width: 100%;
          width: 100%;
          min-width: 100px !important;

          & + .account-select {
            margin-top: 24px;
          }
        }
      }
    }
  }

  .row__title,
  .input-container__label {
    font-size: 14px !important;
    display: block;
    margin-bottom: 16px !important;
    color: $inputLabelColor;
  }

  .title-input,
  .sub-heading {
    font-size: 16px;
    font-weight: 600;
  }

  .two-small-check {
    display: flex;

    @media (max-width: 767px) {
      display: block !important;
    }

    font-size: $fontXXSmall !important;
    letter-spacing: 0 !important;

    .check-line:first-child {
      margin-right: 23px;
    }
  }

  .ngx-datepicker-container {
    .ngx-datepicker-input {
      width: 100% !important;
      font-size: var(--fontXXXXSmall) !important;
      padding-left: 12px !important;
    }

    .calendar-container {
      width: 318px !important;
      border-radius: 0 !important;

      .topbar-container .topbar-title {
        font-family: var(--app-font-primary);
        letter-spacing: $letterSpacing;
        font-size: $fontXXSmall !important;
        color: #5b5b5b !important;
      }
    }

    .ngx-datepicker-position-bottom-right {
      top: 48px !important;
      z-index: 5;
    }
  }
}

.body-content {
  .form {
    width: 80%;
    max-width: 95rem;

    &.full-width {
      width: 100%;
      max-width: 100%;
    }

    @media (max-width: 767px) {
      width: 100%;
      max-width: 100%;
    }
  }
}
