@font-face {
  font-family: 'Accord Alternate';
  src: url('AccordAlternate-Thin.woff2') format('woff2'), url('AccordAlternate-Thin.woff') format('woff');
  font-weight: 100;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: 'Accord Alternate';
  src: url('AccordAlternate-ThinItalic.woff2') format('woff2'), url('AccordAlternate-ThinItalic.woff') format('woff');
  font-weight: 100;
  font-style: italic;
  font-display: fallback;
}

@font-face {
  font-family: 'Accord Alternate';
  src: url('AccordAlternate-ExtraLight.woff2') format('woff2'), url('AccordAlternate-ExtraLight.woff') format('woff');
  font-weight: 200;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: 'Accord Alternate';
  src: url('AccordAlternate-ExtraLightIt.woff2') format('woff2'),
    url('AccordAlternate-ExtraLightIt.woff') format('woff');
  font-weight: 200;
  font-style: italic;
  font-display: fallback;
}

@font-face {
  font-family: 'Accord Alternate';
  src: url('AccordAlternate-Light.woff2') format('woff2'), url('AccordAlternate-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: 'Accord Alternate';
  src: url('AccordAlternate-LightItalic.woff2') format('woff2'), url('AccordAlternate-LightItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
  font-display: fallback;
}

@font-face {
  font-family: 'Accord Alternate';
  src: url('AccordAlternate-Regular.woff2') format('woff2'), url('AccordAlternate-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: 'Accord Alternate';
  src: url('AccordAlternate-Italic.woff2') format('woff2'), url('AccordAlternate-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: fallback;
}

@font-face {
  font-family: 'Accord Alternate';
  src: url('AccordAlternate-Medium.woff2') format('woff2'), url('AccordAlternate-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: 'Accord Alternate';
  src: url('AccordAlternate-MediumItalic.woff2') format('woff2'),
    url('AccordAlternate-MediumItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
  font-display: fallback;
}

@font-face {
  font-family: 'Accord Alternate';
  src: url('AccordAlternate-Bold.woff2') format('woff2'), url('AccordAlternate-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: 'Accord Alternate';
  src: url('AccordAlternate-BoldItalic.woff2') format('woff2'), url('AccordAlternate-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: fallback;
}

@font-face {
  font-family: 'Accord Alternate';
  src: url('AccordAlternate-ExtraBold.woff2') format('woff2'), url('AccordAlternate-ExtraBold.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: 'Accord Alternate';
  src: url('AccordAlternate-ExtraBoldItalic.woff2') format('woff2'),
    url('AccordAlternate-ExtraBoldItalic.woff') format('woff');
  font-weight: 900;
  font-style: italic;
  font-display: fallback;
}
