/* You can add global styles to this file, and also import other style files */
@use 'sass:math';
@import 'app/shared/variables';
@import 'app/shared/quill';
@import 'assets/fonts/Accord Alternate/stylesheet.css';
@import 'app/shared/adaptive';
@import 'app/shared/form-controls';
@import 'app/shared/form';
@import 'app/shared/form-auth';
@import 'app/shared/table';

/* CSS reset */
html {
  font-size: 62.5%; /* the same as font-size: 10px; for ratio 1rem = 10px. */

  @media #{$screen-desktop-qhd} {
    font-size: 68.75%; /* the same as font-size: 11px; */
  }

  @media #{$screen-desktop-xxlg} {
    font-size: 75%; /* the same as font-size: 12px; */
  }

  @media #{$screen-desktop-xxxlg} {
    font-size: 87.5%; /* the same as font-size: 14px; */
  }

  @media #{$screen-desktop-uhd} {
    font-size: 100%; /* the same as font-size: 16px; */
  }
}

*,
::before,
::after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

* {
  outline: none;
  letter-spacing: $letterSpacing;
}

html,
body {
  min-height: 100vh;
  min-width: 320px;
}

body {
  position: relative;
  margin: 0;
  padding: 0;
  font-family: var(--app-font-primary);
  letter-spacing: $letterSpacing;

  .main-container {
    width: calc(100% - 200px);

    .content {
      padding: 0 60px 30px;

      @media (max-width: 1000px) {
        padding: 0;
      }

      &__admin {
        padding: 85px 140px 0;

        @media (max-width: 1366px) {
          padding: 85px 85px 0;
        }

        @media (max-width: 1000px) {
          padding: 85px 0 0;
        }
      }
    }
  }
}

.margin-15 {
  margin-bottom: 0.8rem;
  display: block;
  margin-top: 1.2rem;

  &:first-child {
    margin-top: 0;
  }
}

ul,
ol {
  list-style: none;
  padding: 0;
  margin: 0;
}

.reset-list-style {
  font-size: 1.2rem;

  ul {
    list-style-type: disc;
    list-style-position: inside;
  }

  ol {
    list-style-type: decimal;
    list-style-position: inside;
  }

  ul ul,
  ol ul {
    list-style-type: circle;
    list-style-position: inside;
    margin-left: 15px;
  }

  ol ol,
  ul ol {
    list-style-type: lower-latin;
    list-style-position: inside;
    margin-left: 15px;
  }

  .ql-size-huge {
    font-size: 2.5em;
  }

  .ql-size-large {
    font-size: 1.5em;
  }

  .ql-size-small {
    font-size: 0.75em;
  }

  .ql-size-normal {
    font-size: 1em;
  }
}

a {
  color: var(--app-link-color);
  text-decoration: inherit;
  line-height: 1.6rem;
  font-weight: 400;
}

h1 {
  margin: 0;
  color: var(--app-gray-on-background-color);
  font-style: normal;
  font-weight: 700;
  font-size: 2.4rem;
  line-height: 3.2rem;
  letter-spacing: 0.5px;
  text-align: center;
}

button {
  display: inline-block;
  margin-bottom: 0;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none; // Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214
  border: 1px solid transparent;
  white-space: nowrap;
  font-family: var(--app-font-primary);
  transition: 0.3s ease;
  padding: 0 2rem;
  height: 4rem;
  line-height: 3.8rem;
}

label:focus {
  outline: none;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
}

input {
  font-family: var(--app-font-primary);
  letter-spacing: $letterSpacing;
}

textarea {
  font-family: var(--app-font-primary);
  letter-spacing: $letterSpacing;
}

@media screen and (max-width: 380px) {
  h1 {
    font-size: 20px !important;
  }
}

.field-border {
  border: 1px solid $borderColorDefault;
  border-radius: 2px;
  transition-duration: 0.5s;
}

.error-field-border {
  border-color: $borderColorError !important;
  transition-duration: 0.5s;
}

.error-checkbox-border {
  border-color: $borderColorError !important;
  transition-duration: 0.5s;
}

.error-checkbox-border::before {
  border-color: $borderColorError !important;
  transition-duration: 0.5s;
}

.error-label {
  padding: 14px 0 0;
  font-size: $fontXXXSmall;
  color: var(--app-error-color);
  display: none;
}

.error-label.error {
  display: block;
}

.error-link {
  cursor: pointer;
  text-decoration: underline;
  color: var(--app-error-color);
}

.disabled {
  display: none !important;
}

.enabled {
  display: block !important;
}

.pending {
  background: $backgroundColorContent !important;
}

.ngx-datepicker-container .calendar-container {
  z-index: 1;
}

.day-unit.is-selected {
  background: var(--app-color-main) !important;
  color: white !important;
}

.day-unit.is-today {
  color: white !important;
}

.day-unit.is-today:hover {
  background: $textColorGray !important;
  color: white !important;
}

.right-align-datepicker .calendar-container.ngx-datepicker-position-bottom-right {
  right: 0;
  z-index: 9999;
}

.container .one-elem .date-term-container__datepicker {
  width: 100%;
}

//SHARED ELEMENTS
.success-popup {
  width: 100%;
  max-width: 650px;
  margin: 150px auto 0;
  display: flex;
  justify-content: center;
  background: $backgroundColorInput;
  border: 1px solid $borderColorDefault;
  border-radius: 6px;
  color: var(--textColorPrimary);

  .content {
    display: flex;
    flex-flow: column wrap;
    padding-top: 65px;
    padding-bottom: 35px !important;

    .icon {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background: $backgroundColorAside;
      position: relative;
      margin: 0 auto 30px;

      &.success {
        background: var(--app-color-main);

        &::after {
          content: '';
          position: absolute;
          left: 1.3rem;
          top: 0.4rem;
          width: 14px;
          height: 24px;
          border: solid white;
          border-width: 0 5px 5px 0;
          -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
        }
      }
    }

    .popup-message {
      text-align: center;
      font-size: $fontMedium;
      line-height: 1.56;
      padding: 0 60px;

      @media (max-width: 991px) {
        padding: 0 20px;
      }

      .popup-link {
        margin-top: 40px;
      }

      a {
        color: var(--app-color-main);
        cursor: pointer;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

.content {
  color: var(--textColorPrimary);

  .filter-container {
    top: 34px;
    left: 0;
    right: 0;
    height: 45px;
    display: flex;
    border-bottom: 1px solid $backgroundColorSortBar;

    a {
      margin-right: 30px;
    }

    .sub-menu {
      position: absolute;
      z-index: 500;
      background: #fff;
      border: 1px solid $borderColorSuccess;

      li {
        &:hover {
          background: #f9f9f9;
        }

        a {
          font-size: $fontXXXSmall;
          color: var(--textColorPrimary);
          display: block;
          padding: 10px 15px;
          cursor: pointer;
        }

        &:last-child {
          a {
            margin-bottom: 0;
          }
        }
      }
    }

    @media (max-width: 767px) {
      border-bottom: none;
      z-index: 1;

      &:not(.filter-container-with-sub-menu) {
        a {
          border-bottom: none;

          & + a {
            display: block;
            margin-top: 0.2rem;
          }

          .filter-container__term {
            margin-bottom: 0;
          }

          &.selected {
            .filter-container__term {
              span {
                color: #fff;
              }
            }
          }
        }
      }
    }

    &__term {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      color: var(--textColorPrimary);
      cursor: pointer;
      border-bottom: 2px solid transparent;
      margin-right: 32px;

      i {
        display: none;
      }

      span {
        font-size: 15px;
      }

      @media (max-width: 767px) {
        i {
          display: inline-block;
          width: 20px;
          text-align: center;
          vertical-align: middle;
          position: relative;
          left: 7px;

          &::before {
            font-size: 18px;
          }
        }

        display: inline-block;
        vertical-align: middle;
        margin-bottom: 2%;
        border-bottom: none;
        border-radius: 1px;

        &:not(.selected) {
          i {
            display: none;
          }
        }

        &.selected {
          i {
            display: inline-block;
          }
        }

        span {
          font-size: 13px !important;
        }

        &:first-child {
          margin-right: 1%;
        }

        & + .filter-container__term {
          margin-right: 1%;
        }
      }

      @media (max-width: 767px) {
        display: block;
        margin-bottom: 0.5%;
        position: relative;
        padding: 10px;

        span {
          display: block;
        }

        i {
          position: absolute;
          right: 10px;
          left: auto;
          top: 10px;
          cursor: pointer;
        }

        & + .sub-menu {
          position: relative;
          z-index: 500;
          background: var(--app-color-gray-100);
          border: none;
          top: -5px;
          margin-bottom: 5px;
        }
      }

      &:not(.selected):hover {
        span {
          transition: 0.3s ease;
          color: var(--app-color-main);
        }
      }
    }

    .selected {
      border-bottom: 1px solid var(--app-color-main);
      cursor: unset;

      span {
        color: var(--app-color-main);
      }
    }
  }
}

.sort-bar {
  font-size: $fontXXSmall;

  @media (max-width: 991px) {
    font-size: $fontXXXSmall !important;
  }

  .ng-select {
    input {
      color: var(--textColorPrimary);
      font-size: 12px;
    }

    .ng-select-container {
      min-height: 25px !important;
    }
  }

  .search-container__input {
    width: 100% !important;
    max-width: 355px;
    font-family: var(--app-font-primary);
    letter-spacing: $letterSpacing;

    &.first-elem {
      padding-left: 0;
    }

    &::placeholder {
      color: var(--textColorPrimary);
    }
  }

  .search-container__select {
    border-right-color: $borderColorFilter !important;
  }

  .ngx-datepicker-container .ngx-datepicker-input {
    font-family: var(--app-font-primary);
    letter-spacing: $letterSpacing;
    font-size: 1.3rem !important;
    line-height: 1.6rem;
    color: var(--app-gray-on-background-table-row-color);

    &::placeholder {
      color: var(--app-gray-on-background-table-row-color);
    }
  }

  .search-container__select {
    &.sort-select-container {
    }
  }

  .ng-select span.ng-arrow-wrapper::before {
    //content: url("/assets/icons/arrow_bottom.svg") !important;
    top: -0.1rem !important;
    position: relative !important;
  }

  .ng-select-opened span.ng-arrow-wrapper::before {
    //content: url("/assets/icons/arrow_up.svg") !important;
    top: -0.1rem !important;
    position: relative !important;
  }

  .date-term-container {
    display: flex;
    align-items: center;
    width: 100%;

    &__datepicker {
      position: relative;
      padding-left: 9px;

      &__arrow {
        position: absolute;
        top: calc(50% - 0.2rem);
        right: 10px !important;
        width: 0;
        height: 0;
        border-left: 4px solid transparent !important;
        border-right: 4px solid transparent !important;
        border-top: 5px solid var(--textColorPrimary);
        border-radius: 0 !important;

        @media (max-width: 1191px) {
          top: calc(50% - 6px) !important;
        }
      }
    }

    .search-container__input {
      border: 1px solid #e0e0e0;
      box-sizing: border-box;
      border-radius: 18px;
      font-size: 13px;
      color: $textColorMainDark;
    }

    .search-container__select {
      width: auto;
    }

    @media (max-width: 1191px) {
      .search-container__input,
      .search-container__select {
        height: 25px;
      }
    }

    @media (max-width: 767px) {
      display: block;
      align-items: unset;
      width: 100%;
      flex-flow: column;
      padding: 0;

      .search-container__input {
        margin-top: 20px;
      }

      .search-container__input,
      .search-container__select {
        width: 100% !important;
        border-right: none;
        padding: 0 35px 0 10px;
        height: 35px;
        margin-bottom: 10px;
      }

      .date-term-container__datepicker {
        flex-flow: column;
        width: 100%;
      }
    }
  }
}

.with-hover:hover {
  text-decoration: underline;
  cursor: pointer;
}

.green {
  color: var(--app-link-color);
}

.link-color {
  color: var(--app-link-color);
}

.green-green {
  color: var(--app-color-main);
}

.gray-primary {
  color: var(--textColorPrimary);
}

.date-term-container__datepicker {
  position: relative;

  .own-datepicker__label {
    position: absolute;
    white-space: nowrap;
    font-weight: 500;
    font-size: 1.3rem;
    line-height: 1.6rem;
    color: var(--app-gray-on-background-table-row-color);
  }

  .fas.fa-times {
    position: absolute;
    right: 15%;
    top: 7px;
    font-size: 11px;
    color: #999;
    cursor: pointer;

    &:hover {
      color: var(--app-error-color);
    }
  }
}

/*************************************  scroll  ********************************************/

::-webkit-scrollbar {
  width: 6px !important;
}

::-webkit-scrollbar-thumb {
  background: rgba(#bbb, 0.6);
  border-radius: 2px;
}

::-webkit-scrollbar-track {
  background: rgba(#bbb, 0.15);
  margin: 15px;
}

/**********************************************************************************   */

.extra {
  min-height: 10px !important;
}

.pagination {
  display: flex;
  flex-flow: row nowrap;
  gap: 1rem;
  justify-content: flex-end;
  cursor: pointer;
  color: var(--lightGrey);

  @media (max-width: 767px) {
    justify-content: center;
  }

  .pagination-previous {
    background: url('assets/icons/v2/ic_chevron-right.svg') center no-repeat;
    transform: rotate(180deg);
  }

  .pagination-next {
    background: url('assets/icons/v2/ic_chevron-right.svg') center no-repeat;
  }

  &__arrow {
    i {
      font-weight: 600;
      font-size: 10px;
      position: relative;
      top: -0.3rem;
      color: #666;
    }
  }

  &__page {
    color: var(--textColorGrey);

    span {
      position: relative;
      top: 0;
      font-size: 1.3rem;
      font-weight: 500;
    }

    &:first-child {
    }
  }

  &__arrow,
  &__page {
    width: 3.2rem;
    height: 3.2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background: none;
    transition: 0.2s ease;
    border: 1px solid var(--app-gray-primary-color-70);
    border-radius: 0.4rem;
    font-weight: 600;
    font-size: 1.2rem;
    line-height: 1.4rem;
    color: var(--app-gray-on-background-color);

    &:hover {
      transition: 0.2s ease;
      border: 1px solid var(--app-gray-on-background-table-row-color);
    }
  }

  &__page.active {
    color: var(--app-gray-on-background-color);
    border-color: var(--app-gray-on-background-color);
  }
}

.container {
  position: relative;

  .currency {
    padding-left: 84px;

    @include screen(md) {
      padding-left: 45px;
    }

    @include screen(sm) {
      padding-left: 0;
    }
  }

  &__filters {
    flex-flow: row wrap;
    margin-bottom: 3.2rem;
    display: flex;
    align-items: center;

    .count {
      font-style: normal;
      font-weight: normal;
      font-size: var(--fontXXXSmall);
      display: flex;
      align-items: center;
      line-height: 17px;
      color: var(--app-gray-on-background-color);
    }

    &__actions {
      flex-grow: 1;
      justify-content: flex-end;
      display: flex;
    }
  }

  &__header {
    margin: 20px 0;
    display: flex;
    justify-content: space-between;

    .container__line {
      display: flex;
      align-items: center;

      .container__block:not(:first-child) {
        margin-left: 50px;
      }
    }

    .sort-btn-container {
      width: 100%;

      ng-select span {
        font-size: 12px;
      }
    }

    .button-container {
      position: inherit;
    }
  }

  .button-line {
    display: flex;
    justify-content: space-between;
  }

  .action-icons:not(:last-child) {
    margin-right: 32px;
  }

  .container__title {
    height: 32px;
    display: flex;
    align-items: center;
    font-family: var(--app-font-primary);
    letter-spacing: $letterSpacing;
    font-size: var(--fontXSmall);
    color: var(--textColorBlack);
    font-weight: 600;
  }

  .date-range {
    font-family: var(--app-font-primary);
    font-size: var(--fontXXXSmall);
    color: var(--textColorBlack);
    margin: 0;
  }

  .date-range-container {
    position: absolute;
    left: 0;
    top: -72px;
    height: 32px;
    display: flex;
    align-items: center;
    font-family: var(--app-font-primary);
    font-size: var(--fontXXXSmall);
    color: var(--textColorBlack);
    margin: 0;

    @media (max-width: 767px) {
      left: 10px;
    }

    p {
      margin: 0;
      padding: 0;
    }

    span {
      color: var(--app-color-main);
      font-weight: 600;
    }
  }

  .button-container {
    position: absolute;
    right: 0;
    top: -65px;
    display: flex;
    justify-content: flex-end;

    @media (max-width: 1280px) {
      top: -72px;
    }

    @media (max-width: 1191px) {
      right: 10px;
      top: -72px;
    }

    @media (max-width: 767px) {
      position: unset;
      padding-top: 30px;

      .hide-word {
        display: none;
      }

      .action-icons:not(:last-child) {
        margin-right: 20px;
      }
    }

    &.only-icon {
      top: -50px;

      @media (max-width: 1280px) {
        top: -45px;
      }

      @media (max-width: 1191px) {
        top: -35px;
      }

      .action-icons {
        margin-right: unset;
      }
    }
  }

  .sort-bar {
    min-height: 50px;
    padding: 24px 0;
    display: flex;

    @include screen(md) {
      padding-top: 0;
      flex-wrap: wrap;
      position: relative;
      column-gap: 0.8rem;
      row-gap: 2.4rem;
    }

    .search-container__input {
      padding-left: 1.5rem;
      text-transform: capitalize;

      &::-moz-placeholder {
        line-height: 0.4rem;
      }
    }
  }

  .main {
    background: var(--app-gray-primary-color-20);
    border-radius: 5px;
    margin-bottom: 32px;
    padding-bottom: 22px;

    @media (max-width: 1191px) {
      width: 100%;
    }
  }

  .search-container {
    display: flex;

    &__input,
    &__select {
      background-color: unset;
      border-radius: unset;
      border: unset;
      font-size: 1.3rem;
      font-weight: 500;
      line-height: 1.6rem;
      height: auto;
      padding: 0;
      flex: unset;
      box-shadow: none;
      margin-top: auto;
      color: var(--app-gray-primary-color-80);
    }

    &__input {
      padding-left: 30px;
    }

    &__input::placeholder {
      color: var(--textColorPrimary);
      line-height: unset;
    }

    &__select {
      width: 33.3%;
      margin-left: 50px;
      font-style: normal;
      font-weight: normal;
      font-size: var(--fontXXXXSmall);
      line-height: 16px;

      @media (max-width: 767px) {
        margin-left: 0;
      }

      &:first-child {
        margin-left: 0;
      }

      &.sort-select-container {
        padding: 0 0.4rem 0 0;
        width: auto;

        @media (max-width: 1191px) {
          padding: 0;
        }

        .sort-select {
          width: 100%;
        }
      }
    }
  }

  .two-datepicker {
    display: flex;
  }

  .date-term-container {
    display: flex;
    width: 100%;

    .sort-line-filters {
      display: flex;
      width: 100%;
      justify-content: flex-end;
    }

    .sort-line {
      display: flex;
      width: 100%;

      &:last-child {
        @media (max-width: 1191px) {
          justify-content: flex-start;
        }
      }

      .input-block {
        width: 100%;

        &.part-of-3 {
          min-width: 180px;

          @media (max-width: 1280px) {
            min-width: 160px;
          }

          @media (min-width: 768px) {
            width: 33.3%;
          }
        }
      }

      .input-search {
        max-width: 355px;
        min-width: 183px;
      }

      @media (max-width: 767px) {
        display: block;
      }
    }

    &__datepicker {
      position: relative;
      color: var(--app-gray-on-background-color);
      display: flex;
      align-items: center;
      font-style: normal;
      font-weight: normal;
      font-size: 1.3rem;
      padding: 1.2rem;
      border-radius: 0.4rem;
      transition: 0.2s ease;

      &:hover {
        background: var(--app-gray-primary-color-40);
      }

      &__arrow {
        position: absolute;
        top: calc(50% - 0.1rem);
        right: 15px;
        width: 0;
        height: 0;
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
        border-top: 5px solid var(--textColorPrimary);
        border-radius: 0 !important;
      }
    }
  }

  .bottom {
    display: flex;
    justify-content: space-between;
    padding: 0 0 65px;

    &.bottom_flex_end {
      justify-content: flex-end;
      gap: 8px;
    }

    .icons {
      color: $textColorDim;
      margin-top: 18px;

      span {
        margin: 0 10px;
        cursor: pointer;
        font-size: 13px !important;

        &:hover {
          color: var(--textColorPrimary);
        }
      }
    }

    @media (max-width: 767px) {
      text-align: center;
    }
  }

  .icon {
    &.print::after {
      background-image: url('assets/icons/print.svg');
      background-size: 13px 13px;
    }
  }
}

@include screen(md) {
  .container {
    margin-bottom: 7rem;
  }
}

@media screen and (max-width: 520px) {
  .search-container {
    &__input,
    &__select {
      width: 100% !important;
    }
  }
}

/************************************  settings  **************************************/

.block-menu {
  &.short {
    ul {
      max-width: 50%;
    }
  }

  ul {
    display: flex;

    li {
      a {
        padding: 1.2rem 0;
        margin-right: 32px;
        text-decoration: none;
        color: var(--app-gray-on-background-table-row-color);
        cursor: pointer;
        font-family: var(--app-font-primary);
        font-weight: 600;
        font-size: 1.4rem;
        line-height: 1.671rem;
        letter-spacing: $letterSpacing;
        display: block;
        text-align: center !important;
        margin-bottom: -1px;

        &.link-active {
          font-weight: 600;
          color: var(--app-link-color);
          line-height: 1.61rem;
          margin-bottom: -1px;
        }
      }

      &:last-child a {
        margin-right: 0;
      }
    }
  }

  //@media (max-width: 767px) {
  //  display: none;
  //}
}

.top-btn-absolute {
  position: absolute;
  right: 0;
  top: -83px;
  width: unset;
  padding: 0 20px;

  @media (min-width: 1192px) and (max-width: 1620px) {
    .hide-on-tablet {
      display: none;
    }
  }

  @media (max-width: 767px) {
    margin-top: 10px;
  }
}

.main {
  &::before {
    display: table;
    clear: both;
    content: ' ';
  }
}

/********************************************************************************   */
// Transfer form

.search-box {
  .ng-select.ng-select-single .ng-select-container .ng-value-container .ng-value {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 4.4rem;
    font-size: 1.4rem;
    position: relative;
    color: #5b5b5b;
  }

  .ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input,
  .ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input input {
    font-family: var(--app-font-primary);
    letter-spacing: $letterSpacing;
    font-weight: 400;
    top: -0.1rem !important;
    left: 1rem !important;
    font-size: 1.4rem !important;
    color: #5b5b5b !important;
    overflow: visible;
  }

  .select-container-searchable .loupe {
    right: 20px;
    opacity: 0.6;
  }

  .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-disabled {
    font-family: var(--app-font-primary);
    font-weight: 300;
    letter-spacing: $letterSpacing;
    position: relative;
    top: 0.1rem;
    font-size: 14px;
    color: #aaa !important;
  }

  .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
    font-family: var(--app-font-primary);
    font-weight: 400;
    letter-spacing: $letterSpacing;
    position: relative;
    top: 0.1rem;
    font-size: 14px;
    padding-left: 20px;
  }

  .select-container-searchable {
    margin-top: 0 !important;

    .ng-select-container {
      .ng-placeholder {
        font-family: var(--app-font-primary);
        font-weight: 400;
        letter-spacing: $letterSpacing;
        position: relative;
        top: 0;
        font-size: 14px;
      }
    }
  }
}

.two-in-row,
.one-in-row {
  .ng-select {
    &.large {
      .ng-value-container {
        padding-left: 0;
      }

      & + .select-container-large__tip {
        left: 20px !important;
      }
    }
  }

  .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-disabled {
    font-family: var(--app-font-primary);
    font-weight: 300;
    letter-spacing: $letterSpacing;
    position: relative;
    top: 0.1rem;
    font-size: 14px;
    color: #aaa !important;
  }

  .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
    font-family: var(--app-font-primary);
    font-weight: 400;
    letter-spacing: $letterSpacing;
    position: relative;
    top: 0.1rem;
    font-size: 14px;
    padding-left: 20px !important;
  }

  .ng-select-container .ng-value-container {
    padding: 0.1rem;

    .top-hint {
      font-family: var(--app-font-primary);
      font-weight: 400;
      position: relative;
      top: -0.4rem;
      font-size: 14px !important;
      letter-spacing: $letterSpacing;
    }

    .select-value {
      font-family: var(--app-font-primary);
      font-weight: 400;
      letter-spacing: $letterSpacing;
      position: relative;
      top: 5px;
      color: #5b5b5b;
      font-size: 16px !important;
    }

    .bottom-hint {
      font-family: var(--app-font-primary);
      font-weight: 400;
      letter-spacing: $letterSpacing;
      margin-top: 15px !important;
      font-size: 14px !important;
    }
  }

  .ng-dropdown-panel.large {
    top: 105% !important;

    .ng-option {
      .select-value {
        font-family: var(--app-font-primary);
        font-weight: 400;
        letter-spacing: $letterSpacing;
        position: relative;
        top: -0.2rem;
        font-size: 16px !important;
        margin-bottom: 0.4rem;
        color: #5b5b5b;
      }

      .bottom-hint {
        font-family: var(--app-font-primary);
        font-weight: 400;
        letter-spacing: $letterSpacing;
        font-size: 14px !important;
      }
    }
  }
}

.field-pair-in-row,
.field-single-in-row {
  .ng-select.ng-select-single .ng-select-container .ng-value-container {
    overflow: visible !important;
  }

  .ng-select.ng-select-single .ng-select-container .ng-value-container .ng-value {
    white-space: nowrap;
    overflow: visible !important;
    text-overflow: ellipsis;
    line-height: 4.4rem;
    font-size: 1.4rem;
    position: relative;
    top: 0.1rem;
    color: #5b5b5b;
  }

  .ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input,
  .ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input input {
    font-family: var(--app-font-primary);
    letter-spacing: $letterSpacing;
    font-weight: 400;
    top: -0.1rem !important;
    left: 10px !important;
    font-size: 1.4rem !important;
    color: #5b5b5b !important;
    overflow: visible;
  }

  .ng-select-container {
    .ng-placeholder {
      font-family: var(--app-font-primary);
      font-weight: 400;
      letter-spacing: $letterSpacing;
      position: relative;
      top: 0;
      font-size: 1.4rem;
    }
  }
}

body {
  .content {
    .nav-menu {
      @media (max-width: 767px) {
        display: block;
        background: none;

        .nav-menu__item {
          display: block;
          background: var(--app-color-gray-100);
          padding: 16px 16px 13px;
          margin-bottom: 5px;
          border-left: 4px solid #ccc;

          &.active {
            border-color: #94c817;
          }

          &::before {
            display: none;
          }
        }
      }
    }
  }
}

.hidden-md {
  display: none;

  @media (max-width: 991px) {
  }
}

.container {
  .bottom {
    &.footnote {
      font-family: var(--app-font-primary);
      font-weight: 600;
      letter-spacing: $letterSpacing;
      font-size: $fontXXXXSmall;
      color: $textColorDim;
      margin-bottom: 30px;
      line-height: 1.7;
    }

    button + .notification {
      display: block;
    }
  }
}

.content {
  .heading,
  .header-page {
    font-size: $fontMedium !important;
    color: #5b5b5b;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.5px !important;
  }

  .header-page {
    margin-bottom: 28px;
  }
}

.line,
.row {
  &.sub-heading {
    margin-top: 28px;
    margin-bottom: 24px;
    font-size: $fontXSmall;
    font-family: var(--app-font-primary);
    letter-spacing: $letterSpacing;
    font-weight: 600;

    &.no-margin-bottom {
      margin-bottom: 0;
    }

    &.no-margin-top {
      margin-top: 0;
    }
  }

  .hr {
    height: 0;
    width: 100%;
    border-top: 1px solid var(--tabsBottomBorderColor);
    margin-top: 8px;
  }
}

.table-content {
  margin-bottom: 32px;
  width: 100%;
  padding: 24px 0;
  background: inherit;
  border-radius: 5px;

  &.transfer-pending {
    padding-bottom: 0;
    border-bottom: unset;
  }

  &.no-line {
    border-bottom: unset;
    margin-bottom: 0;
  }

  label.field-name {
    font-family: var(--app-font-primary);
    font-size: $fontXXXSmall !important;
    display: block;
    margin-bottom: 15px !important;
    color: $inputLabelColor;
    letter-spacing: $letterSpacing;
    position: relative;

    .zmdi.zmdi-help {
      color: $backgroundColorAside;
      font-size: $fontMedium;
      cursor: pointer;
      position: absolute;
      top: -0.4rem;
      right: 0.3rem;
    }
  }

  .line {
    display: flex;
    font-size: $fontMedium;
    color: var(--textColorPrimary);
    margin-bottom: 2.4rem;
    gap: 4.4%;

    @media (max-width: 767px) {
      display: flex;
      flex-flow: row wrap;
    }

    &.sub-title {
      font-size: $fontXSmall !important;
      font-family: var(--app-font-primary);
      letter-spacing: $letterSpacing;
      font-weight: 600;
    }

    &.display-block {
      display: block;
    }

    i {
      // margin-left: 1.2rem;

      &.zmdi-delete {
        margin-left: 1rem;
        padding: 0.2rem;
        cursor: pointer;
      }
    }

    .checkbox-container {
      position: relative;
    }

    &.no-margin {
      margin-bottom: 0;
    }

    .check-line {
      display: flex;
      align-items: center;

      .checkbox__text {
        font-family: var(--app-font-primary);
        font-style: normal;
        font-weight: normal;
        font-size: var(--fontXXXSmall);
        line-height: 19px;
        color: var(--colorSubtitleGrey);
      }
    }

    .single-elem {
      width: 100%;
    }

    .elem {
      @media (min-width: $mobileSize) {
        max-width: 47.8%;
      }

      @media (max-width: $mobileSize) {
        max-width: 100%;

        &:nth-child(2) {
          padding-top: 2.4rem;
        }
      }

      width: 100%;

      .select-container {
        padding-left: 0;
      }
    }

    .native-input {
      width: 100%;
    }

    .lock-field {
      background: var(--app-gray-on-primary-color);
    }

    .native-text-area {
      font-family: var(--app-font-primary);
      padding: 1.2rem;
      font-size: 1.6rem;
      line-height: 2.4rem;
      width: 100%;
      background: var(--app-color-gray-50);
      border: 1px solid var(--app-color-gray-50);
      color: var(--app-color-gray-900);
      transition: border 0.3s;
      box-sizing: border-box;
      border-radius: 1rem;

      &:active {
        border-color: var(--app-color-primary-700);
      }
    }

    .native-select.error-field-border {
      margin-bottom: 3rem;

      &.error-field-border {
        margin-bottom: unset;
      }

      @media (max-width: 991px) {
        margin-bottom: 5rem;
      }
    }
  }

  .sub-heading {
    font-size: 1.6rem;
    font-weight: 600;
    color: var(--app-gray-on-background-color);
    padding: 1.2rem 0 2rem;
    margin-top: 0;
    border-bottom: 1px solid var(--app-gray-primary-color-60);

    label {
      color: var(--app-gray-on-background-color);
    }
  }

  .transfer-heading {
    color: var(--app-gray-on-background-color);
    padding: 12px 0;
    margin-top: 0 !important;
    background-color: unset;
    display: flex;
    align-items: center;

    .transfer-elem {
      display: flex;
      align-items: center;
    }

    span {
      height: 17px;
      margin-right: 10px;
    }
  }

  .other-text {
    font-family: var(--app-font-primary);
    font-weight: normal;
    font-style: normal;
    letter-spacing: $letterSpacing;
    font-size: var(--fontXXXSmall);
    color: #b6b6b6;
    line-height: 1.7;
    margin-top: 6px;
    display: block;
  }

  .flex-line {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    font-size: $fontXXXSmall;

    @media (max-width: 767px) {
      font-size: $fontXXXSmall;
      line-height: 1.6;

      .elem {
        margin-left: 50px;
      }
    }
  }
}

.footnote {
  font-family: var(--app-font-primary);
  font-weight: normal;
  letter-spacing: $letterSpacing;
  font-size: 13px;
  color: var(--colorSubtitleGrey);
  margin-top: 10px;
  line-height: 1.3;
  display: block;

  @media (max-width: 767px) {
    text-align: left !important;
  }
}

.success {
  color: $mainColorStatus;

  .status-block {
    background-color: $mainColorStatus;
  }

  &::first-letter {
    text-transform: uppercase;
  }
}

.status_executed {
  color: var(--app-success-color);

  .status-block {
    background-color: var(--app-success-color);
  }

  &::first-letter {
    text-transform: uppercase;
  }
}

.status_active {
  color: var(--textColorPrimary) !important;
}

.status_dormant {
  color: $pendingColorStatus;

  .status-block {
    background-color: $pendingColorStatus;
  }
}

.status_blocked {
  color: $errorColorStatus;

  .status-block {
    background-color: $errorColorStatus;
  }
}

.medium {
  color: $textColorMedium !important;

  .status-block {
    background-color: $textColorMedium !important;
  }

  &::first-letter {
    text-transform: uppercase;
  }
}

.status_pending {
  color: $pendingColorStatus;

  .status-block {
    background-color: $pendingColorStatus;
  }

  &::first-letter {
    text-transform: uppercase;
  }
}

.status_blocked {
  &::first-letter {
    text-transform: uppercase;
  }
}

.amount_plus {
  color: var(--app-success-color);
}

.amount_minus {
  color: var(--app-error-color);
}

.status_canceled {
  color: var(--app-error-color) !important;

  .status-block {
    background-color: var(--app-error-color) !important;
  }

  &::first-letter {
    text-transform: uppercase;
  }
}

.success-link {
  font-size: $fontXXSmall;
  color: var(--app-color-main);
  cursor: pointer;
  transition: 0.2s ease;

  &:hover {
    transition: 0.2s ease;
    text-decoration: underline;
  }
}

.back {
  color: var(--textColorPrimary);
  font-family: var(--app-font-primary);
  letter-spacing: $letterSpacing;
  font-weight: 600;
  font-size: $fontXXXSmall;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.min-pass-length {
  .bar-label {
    flex: 32% !important;
  }

  .points-container {
    flex: 40% !important;
  }
}

.container .main .table .table__body tr.no-search-results {
  cursor: unset;

  &:hover {
    background: unset !important;
  }

  td {
    text-align: -webkit-center;
    text-align: -moz-center;
    padding: 2.2rem 1rem !important;
    color: $textColorDim;
    font-size: $fontXXXSmall;

    &::before {
      width: 0;
    }

    .no-search-icon {
      display: inline-block;
      padding-left: 2.4rem;
      background: 0 20% url('assets/icons/no-search.png') no-repeat;
      background-size: 1.4rem 1.4rem;
      height: 1.4rem;
    }

    &::before {
      width: auto;
    }

    @media screen and (max-width: 992px) {
      &::before {
        width: auto;
      }
    }
  }
}

.powered-wrapper {
  position: absolute;
  width: 100%;
  bottom: 0;
  padding: 1.3rem 0 1rem;

  .text-container {
    margin: 0 auto;
    text-align: right;
    font-size: 13px !important;
    color: $textColorDim;
    padding: 0 60px;

    @media (min-width: 1680px) {
      padding: 0 80px;
    }

    @media (max-width: 1679px) {
      padding: 0 60px;
    }

    @media (max-width: 1399px) {
      padding: 0 30px;
    }

    @media (max-width: 767px) {
      padding: 0 20px;
    }

    i.far {
      font-size: 10px;
      position: relative;
      top: -7px;
      right: 0.2rem;
    }
  }
}

.title-line {
  height: 80px;
  background: $backgroundColorContent;
  color: var(--textColorPrimary);
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  padding: 0 60px 0 30px;

  @media (max-width: 1280px) {
    height: 70px;
  }

  @media (min-width: 1680px) {
    padding: 0 80px;
  }

  @media (min-width: 1440px) and (max-width: 1679px) {
    padding: 0 60px;
  }

  @media (min-width: 768px) and (max-width: 1399px) {
    padding: 0 30px;
  }

  @media (max-width: 767px) {
    padding: 0 20px;
  }

  .page-name {
    font-size: $fontMedium;
  }

  .title-btn {
    cursor: pointer;
    color: $textColorDim;
    font-size: $fontXXXSmall;

    i {
      font-size: 20px;
      margin-right: 10px;
      vertical-align: middle;
    }

    &:hover {
      color: var(--textColorPrimary);
    }
  }
}

/*******************************************************************   */

.relative-block {
  position: relative;

  .absolute-btn {
    position: absolute;
    right: 0;
    top: -100px;
  }
}

.information-fields {
  .line {
    margin-bottom: 1.9rem !important;
  }

  .block-line {
    display: flex;
    flex-flow: wrap;

    .block {
      width: 100%;
      margin-bottom: 12px;

      &.single {
        width: 100%;
        margin-bottom: 0;
      }

      .row.sub-heading {
        margin-top: 0;
        margin-bottom: 7px;
        padding: 12px 32px;
        background: #f6f6f6;
        color: #333;
      }

      .row:not(.sub-heading) {
        display: flex;
        align-items: center;
        font-size: $fontXXXSmall;
        font-family: var(--app-font-primary);
        letter-spacing: $letterSpacing;

        .row-item {
          width: 50%;
          min-width: 50%;
        }

        .clip {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        &.with-input {
          padding: 0.4rem 0;
        }

        .fields-container__row {
          margin: 0 13px 0 16px;
        }

        .title {
          color: var(--app-gray-primary-color-100);
          display: block;
          margin-right: 10px;
          font-style: normal;
          font-weight: 500;
          font-size: var(--fontXXXSmall);
          line-height: 13px;

          &.checkbox-name {
            color: $checkboxTitleColor;
          }
        }
      }
    }
  }

  .block-line:not(:last-child) {
    .block:last-child {
      margin-bottom: 32px;
    }
  }

  .block-line:last-child {
    .block:last-child {
      margin-bottom: 13px;
    }
  }
}

.with-title {
  padding-top: 60px;

  @media (max-width: 767px) {
    padding-top: 80px;
  }
}

// remove during refactoring
.with-title-30 {
  //padding-top: 30px;
  //@media (max-width: 767px) {
  //  padding-top: 50px;
  //}
}

.information {
  &__sub-heading {
    padding: 12px 32px;
    background-color: var(--backgroundSubHeaddingColor);
    font-size: var(--fontXXXSmall);
    font-family: var(--app-font-primary);
    letter-spacing: 0.05px;
    font-weight: 600;
    color: #484848;
  }

  &__block {
    max-width: 966px;
    padding: 6px 0 40px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    &.information__block--column {
      flex-direction: column;
    }

    .information__item {
      width: calc(50% - 16px);
      padding: 18px 0 6px;
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid var(--borderBottomColorLine);

      .title {
        display: block;
        min-width: 50%;
        font-style: normal;
        font-weight: 500;
        font-size: var(--fontXXXSmall);
        line-height: 13px;
        color: var(--app-gray-primary-color-100);
      }

      .clip {
        display: block;
        min-width: 50%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-style: normal;
        font-weight: normal;
        font-size: var(--fontXXXSmall);
        line-height: 16px;

        & .capitalize {
          text-transform: capitalize;
        }

        &.status_executed,
        .success {
          color: var(--app-color-main) !important;
        }

        &.status_active {
          color: var(--textColorMainDark);
        }

        &.status_pending,
        .medium {
          color: var(--textColorPrimary);
        }

        &.status_canceled,
        .error {
          color: var(--textColorError);
        }
      }
    }

    @include screen(sm) {
      flex-direction: column;

      .information__item {
        width: 100%;
      }
    }
  }
}

/****************************************  notification  *********************************************/

.simple-notification-wrapper.right {
  right: 45px !important;
}

.simple-notification-wrapper {
  z-index: 10001 !important;
}

.simple-notification {
  font-family: var(--app-font-primary);
  width: 316px !important;
  height: 108px !important;
  letter-spacing: $letterSpacing;
  background: #27282c !important;
  box-shadow: 0 6.92308px 22.1538px rgb(0 0 0 / 5%) !important;
  border-radius: 5px;
  padding: 14px 17px !important;

  &.has-icon .sn-title {
    padding-left: 25px !important;
  }

  & > div.ng-star-inserted {
    display: flex !important;
    align-items: flex-start !important;
    flex-flow: column !important;
  }

  .icon {
    right: unset !important;
    top: unset !important;

    .icon-error {
      position: absolute;
      background-image: url('/assets/icons/notifications/error.svg');
      width: 20px;
      height: 20px;
    }

    .icon-success {
      position: absolute;
      background-image: url('/assets/icons/notifications/sucess.svg');
      width: 20px;
      height: 20px;
    }
  }

  &.success,
  &.error,
  &.warn {
    .sn-title {
      color: var(--app-color-white) !important;
    }
  }

  .sn-title {
    font-family: var(--app-font-primary);
    font-style: normal;
    font-weight: 600;
    font-size: var(--fontXXXXSmall) !important;
    line-height: 20px !important;
    padding: 0 !important;
    text-align: -webkit-center !important;
  }

  .sn-content {
    font-style: normal;
    font-weight: normal;
    font-size: var(--fontXXXXSmall) !important;
    padding-top: 6px !important;
    color: var(--lightGrey) !important;
  }

  .icon {
    margin-right: 20px !important;
    width: unset !important;
    height: 20px !important;
    padding: unset !important;
  }
}

.action-icons {
  display: flex;
  margin-left: 8px;

  .icon {
    color: $iconsColor;
    font-size: 15px;
    cursor: pointer;
    display: flex;
    align-items: center;

    &:hover {
      transition: 0.2s ease;
      color: var(--textColorPrimary);
    }

    &:not(:first-child) {
      margin-left: 16px;

      @media (max-width: 767px) {
        margin-left: 20px;
      }
    }

    .zmdi.zmdi-print {
      font-size: 19px;
    }
  }
}

.card-input {
  background: 16px 50% url('assets/icons/v2/ic_card.svg') no-repeat;
  background-size: 20px 14px;
  padding-left: 50px;
  text-align: -webkit-center;
}

.advices {
  .advice-container__image {
    border-color: $borderColorSuccess !important;

    svg {
      g {
        stroke: $borderColorSuccess !important;
      }
    }
  }
}

.section {
  border-top-color: var(--app-color-main) !important;

  svg {
    g,
    path,
    rect {
      stroke: $borderColorSuccess !important;
    }
  }
}

.per-page-select {
  .mobile-hide {
    display: none !important;
  }

  .page-select-container {
    min-width: 5rem;

    @media (min-width: 1191px) {
      border: 1px solid var(--app-gray-primary-color-70);
      border-radius: 0.4rem;
    }

    &:hover {
      border: 1px solid var(--app-gray-on-background-table-row-color);
    }
  }
}

/***************************  news-style  *******************************/

.news-text {
  a {
    color: var(--app-color-main);
    text-decoration: underline;

    &:hover {
      text-decoration: unset;
    }
  }

  table {
    td {
      border: 1px solid $borderColorDim;
      padding: 12px 0 12px 15px;

      @media (max-width: 1280px) {
        padding: 12px 15px;
        font-size: 12px;
      }
    }
  }

  h1 {
    font-size: 24px;
  }

  h2 {
    font-size: 22px;
  }

  h3 {
    font-size: 20px;
  }

  h4 {
    font-size: 18px;
  }

  b {
    font-weight: bold;
  }
}

/******************************************************************   */

.transfer-with-angle {
  position: relative;

  &::after {
    content: '';
    display: block;
    width: 9px;
    height: 22px;
    background: url('assets/icons/transfer/right-angle.svg') no-repeat;
    background-size: contain;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    @media (max-width: 991px) {
      content: none;
    }
  }
}

/* feature for input number - remove arrow */
input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number']:-moz-submit-invalid {
  box-shadow: none;
}

input[type='number']:-moz-ui-invalid {
  box-shadow: none;
}

input[type='number'] {
  -moz-appearance: textfield;
}

/* fix dropdown panel */
.ng-dropdown-panel {
  min-height: min-content !important;
}

@media (max-width: 1191px) {
  .temp-class .search-container__input,
  .temp-class .date-term-container__datepicker {
    display: none;
  }

  .container .sort-bar.temp-class {
    min-height: unset;
    margin-bottom: unset;
  }
}

.header__logo-container__image {
  background: 50% 50% url('/assets/images/logo/logo-full.svg') no-repeat;
  background-size: contain;
  width: 8.4rem;
  height: 3.2rem;
}

.aside {
  .dashboard::before {
    content: url('/assets/icons/transfer.svg');
    font-size: 14px;
  }

  .transaction::before {
    //content: url('/assets/icons/transactions.svg');
    font-size: 14px;
  }

  .subscriptions::before {
    content: url('/assets/icons/subscriptions.svg');
    font-size: 14px;
  }

  .manage::before {
    content: url('/assets/icons/manage.svg');
  }

  .reports::before {
    content: url('/assets/icons/reports.svg');
    font-size: 14px;
  }

  .messages::before {
    font-size: 14px;
    content: url('/assets/icons/messages.svg');
    vertical-align: middle;
  }

  .log::before {
    font-size: 14px;
    content: url('/assets/icons/log.svg');
    vertical-align: middle;
  }

  .accounts::before {
    content: url('/assets/icons/accounts.svg');
    top: 6px;
  }

  .settings::before {
    font-size: 14px;
    content: url('/assets/icons/settings.svg');
    vertical-align: middle;
  }
}

/* ngx-file-drop */
.drop-zone,
.ngx-file-drop__drop-zone {
  display: flex !important;
  justify-content: center !important;
  border: 0 !important;
  border-radius: 0 !important;
  align-items: center !important;
}

.dnd-gallery img {
  max-width: 320px;
  margin-bottom: 10px;
  margin-right: 10px;
  vertical-align: middle;
}

.upload-table {
  display: flex;
  justify-content: center;
}

.hidden-block {
  display: none !important;
}

.spinner {
  color: var(--app-color-main);
}

.spinner-text {
  position: fixed;
  top: calc(54% + 27px);
  right: calc(50% - 245px);
  text-align: center;
  z-index: 100000;
  font-size: $fontXXLarge;
}

.black-overlay {
  position: fixed !important;
  right: 0;
  bottom: 0;
}

.advice-container__text {
  a,
  p,
  i,
  b,
  li,
  strong {
    color: var(--app-color-main);
  }
}

// truncation

.clip-wrapper .clip-block {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.max-width-truncation {
  max-width: 470px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.td-max-width-trunc {
  max-width: 220px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @include width(1750px) {
    max-width: 110px;
  }
}

.td-max-width-report {
  max-width: 170px;
}

// truncation new

.td-max-width-min {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (min-width: 767px) and (max-width: 1280px) {
    max-width: 85px;
  }

  @media (min-width: 1280px) {
    max-width: 100px;
  }
}

.td-max-width-mid {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (min-width: 767px) and (max-width: 1280px) {
    max-width: 100px;
  }

  @media (min-width: 1280px) {
    max-width: 120px;
  }
}

.td-max-width-max {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (min-width: 767px) and (max-width: 1280px) {
    max-width: 170px;
  }

  @media (min-width: 1280px) {
    max-width: 220px;
  }
}

.td-max-width-super-max {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

app-date-to-from-form-line .elem .date-term-container__datepicker__icon {
  top: 12px;
}

/*****************************  Input  **************************************/
.form__group {
  position: relative;
  padding: 15px 0 0;
  margin-top: 10px;
}

.form__field {
  width: 100%;
  height: 47px;
  font-style: normal;
  font-weight: normal;
  font-family: var(--app-font-primary);
  border: 0;
  border-bottom: 1px solid $borderColorLine;
  outline: 0;
  font-size: $fontXSmall;
  color: var(--colorBlack);
  padding: 7px 46px;
  background: transparent;
  transition: border-color 0.2s;

  &.form__field--select {
    padding: 0;
  }

  &::placeholder {
    color: var(--textColorPrimary);
  }

  &:placeholder-shown ~ .form__label {
    cursor: text;
    top: 15px;
    font-style: normal;
    font-weight: normal;
    font-size: $fontXSmall;
    line-height: 19px;
  }
}

.form__label {
  position: absolute;
  top: 0;
  display: block;
  transition: 0.2s;
  font-size: $fontXXXSmall;
  color: $textColorLabel;
}

.form__field:focus {
  ~ .form__label {
    position: absolute;
    top: 0;
    display: block;
    font-size: $fontXXXSmall;
    color: var(--app-color-main);
  }

  border-width: 1px;
  border-color: var(--app-color-main);
}

/* reset input */
.form__field {
  &:required,
  &:invalid {
    box-shadow: none;
  }
}

//checkbox

.md-checkbox {
  position: relative;
  text-align: left;

  &.md-checkbox-inline {
    display: inline-block;
  }

  label {
    cursor: pointer;
    display: inline;
    color: #c4c4c4;
    font-size: $fontXXSmall;
    line-height: 1.2em;
    vertical-align: top;
    clear: both;
    padding-left: 0.1rem;

    &:not(:empty) {
      padding-left: 1.2rem;
    }

    &::before,
    &::after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
    }

    &::before {
      // box
      width: $md-checkbox-size;
      height: $md-checkbox-size;
      background: #fff;
      border: $md-checkbox-border-width solid $md-checkbox-border-color;
      border-radius: $md-checkbox-border-radius;
      cursor: pointer;
      transition: background 0.3s;
    }
  }

  input[type='checkbox'] {
    outline: 0;
    visibility: hidden;
    width: $md-checkbox-size;
    margin: 0;
    display: block;
    float: left;
    font-size: inherit;

    &:checked {
      + label::after {
        $md-checkmark-size: $md-checkbox-size - 2 * $md-checkbox-padding;

        transform: translate($md-checkbox-padding, math.div($md-checkbox-size, 2) - math.div($md-checkmark-size, 2.6))
          rotate(-45deg);
        width: $md-checkmark-size;
        height: math.div($md-checkmark-size, 2);
        border: $md-checkmark-width solid var(--colorBlack);
        border-top-style: none;
        border-right-style: none;
      }
    }

    &:disabled {
      + label::before {
        border-color: $md-checkbox-border-color-disabled;
      }

      &:checked {
        + label::before {
          background: $md-checkbox-checked-color-disabled;
        }
      }
    }
  }
}

.container-description {
  display: flex;
  justify-content: center;
}

.container-drag-and-drop {
  margin-bottom: 35px;
  background: var(--app-color-white);
  box-shadow: 0 10px 32px rgb(0 0 0 / 5%);
}

.container-drag-and-drop.error-field {
  border: 1px solid $borderColorError !important;
  transition-duration: 0.5s;

  .error-word {
    display: none;
  }
}

.ng-select .ng-select-container {
  background: unset;
}

.line-filter-datepicker {
  position: relative;

  &.own-datepicker__from {
    .ngx-datepicker-container .ngx-datepicker-input {
      width: 97px !important;
      padding-left: 80px !important;
    }
  }

  &.own-datepicker__to {
    .ngx-datepicker-container .ngx-datepicker-input {
      width: 97px !important;
      padding-left: 80px !important;
    }
  }

  &.is-selected.own-datepicker__from .ngx-datepicker-container .ngx-datepicker-input {
    width: 175px !important;
  }

  &.is-selected.own-datepicker__to .ngx-datepicker-container .ngx-datepicker-input {
    width: 175px !important;
  }

  .ngx-datepicker-container::after {
    content: '';
    display: block;
    width: 1.2rem;
    height: 1.2rem;
    background: url('assets/icons/v2/ic_calendar.svg') no-repeat center;
    background-size: contain;
    position: absolute;
    top: 0.25rem;
    right: 0;
    z-index: 0;
  }

  &.is-selected .ngx-datepicker-container::after {
    display: none;
  }
}

.own-datepicker__reset {
  display: block;
  position: absolute;
  padding: 0.8rem 0.2rem;
  right: 1.6rem;
  height: 25px !important;
  width: 14px !important;
  z-index: 1;

  .icon {
    cursor: pointer;
  }
}

.date-term-container__datepicker__small {
  .line-filter-datepicker {
    &.is-selected.own-datepicker__to .ngx-datepicker-container .ngx-datepicker-input {
      width: 130px !important;
    }

    &.own-datepicker__to {
      .ngx-datepicker-container .ngx-datepicker-input {
        padding-left: 40px !important;
      }
    }
  }
}

.ngx-datepicker-container .ngx-datepicker-input {
  padding: 0 !important;
  width: 39px !important;
  color: var(--mediumDarkGrey);
  position: relative;
  z-index: 1 !important;

  &::placeholder {
    color: var(--disableColorButtom);
    cursor: pointer;
  }
}

.is-selected {
  .ngx-datepicker-container .ngx-datepicker-input {
    width: 116px !important;
  }
}

.top-header-for-super {
  font-size: 14px;
  height: 31px;
  line-height: 31px;
  background-color: #5b5b5b;
  text-align: center;
  color: #fff;
  margin-bottom: 10px;
  box-shadow: 0 2px 9px #7a7a7a;
}

//TABS PANEL

.tabs-panel {
  display: flex;
  gap: 1.6rem;
  justify-content: left;
  align-items: flex-start;

  .tabs-panel__tab {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 1rem 0.8rem;
    color: var(--app-gray-on-background-table-row-color);
    border-radius: 0.6rem;
    font-size: 1.4rem;
    line-height: 1.671rem;
    font-weight: 500;
    text-decoration: none;

    &:hover {
      background: var(--app-gray-primary-color-40);
    }

    &.checked {
      color: var(--app-link-color);

      &:hover {
        color: var(--app-gray-on-background-table-row-color);
      }
    }
  }
}

.error-field .ng-select.large.ng-select-single .ng-select-container,
.error-field .ng-select.multiple .ng-select-container {
  border: 1px solid var(--app-error-color) !important;
  border-color: var(--app-error-color) !important;
  transition: 0.5s !important;
  background: #fff !important;
  border-radius: 3.2px;

  &.empty-field {
    background: var(--app-gray-primary-color-70);
    color: var(--textColorPrimary);
  }
}

.popup {
  .shadow {
    display: none;
    position: fixed;
    z-index: 5;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: #000;
    opacity: 0.5;
  }

  .modal {
    max-height: 100vh;
    max-width: 900px;
    z-index: 5;
    width: 100%;
    display: none;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: $backgroundColorHeader;

    @media (max-width: 500px) {
      padding: 30px 20px;
    }

    &__close {
      position: absolute;
      top: 12px;
      right: 12px;
    }

    &__header {
      margin: 4.3rem 0 2.4rem;
      padding: 0 3.2rem;
      font-size: var(--fontLarge);
      font-weight: 600;
      line-height: 100%;
      color: var(--colorPopupTitle);
    }

    &__body {
      font-size: var(--fontXSmall);
      font-family: var(--app-font-primary);
      line-height: 150%;
      max-height: 45rem;
      overflow: auto;
      color: var(--colorPopupText);
      margin: 0 3.2rem 2.4rem;

      p {
        margin-bottom: 1.4rem;
      }

      @media (max-width: 500px) {
        max-height: 30vh;
        padding-right: 2rem;
      }

      .modal__sub-heading {
        font-size: var(--fontXSmall);
        font-family: var(--app-font-primary);
        font-weight: 600;
        line-height: 100%;
        margin: 2.4rem 0 1.9rem;
      }

      .note {
        font-style: normal;
        font-weight: normal;
        font-size: var(--fontMedium);
        line-height: 100%;
        color: var(--colorSubtitleGrey);
        margin-bottom: 2.1rem;
      }

      .note-bold {
        font-weight: 600;
        color: var(--colorPopupTitle);
      }

      .list-title {
        margin-bottom: 1.4rem;
        font-size: var(--fontMedium);
        font-family: var(--app-font-primary);
        line-height: 150%;
      }

      .paragraph {
        margin-bottom: 2.4rem;
        font-size: var(--fontMedium);
        font-family: var(--app-font-primary);
        line-height: 150%;
      }

      .list {
        .list-item {
          margin-bottom: 18px;
          font-size: var(--fontMedium);
          font-family: var(--app-font-primary);
          line-height: 150%;

          .list {
            margin: 18px 0 0 34px;
          }

          &::before {
            content: '';
            border: 3px solid var(--colorPopupTitle);
            border-radius: 50%;
            box-sizing: border-box;
            display: inline-block;
            width: 5px;
            height: 5px;
            margin-right: 8px;
            vertical-align: middle;
          }
        }

        &.list--little-font {
          .list-item {
            font-size: var(--fontXSmall);
          }
        }
      }

      .grey-line {
        border-bottom: 1px solid $borderColorDefault;
        margin: 30px 0;
      }
    }

    &__footer {
      display: flex;
      justify-content: flex-end;
      padding: 26px 32px 32px;
      border-top: 1px solid var(--borderBottomColorLine);
    }
  }

  &.description {
    .modal__body {
      margin-bottom: 24px;

      .list .list-item {
        margin-bottom: 0;
      }
    }
  }

  .visible {
    display: block;
  }

  .fadeIn {
    animation-name: fadeIn;
    animation-duration: 0.5s;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
}

.block-information {
  .block {
    width: 100%;

    &-line {
      display: flex;
      flex-wrap: wrap;
    }

    &-row {
      flex-direction: column;
      align-content: flex-start;

      .row {
        margin-bottom: 20px !important;
      }
    }

    .row {
      margin: 0 32px 0 0;
      width: 43%;
      font-style: normal;
      font-weight: normal;
      font-size: var(--fontXXXXSmall);
      line-height: 16px;
      color: var(--lightGrey);
      border-bottom: 1px solid var(--app-color-gray-100);

      span {
        width: 50%;

        .title {
          color: var(--app-gray-on-background-color);
        }
      }
    }
  }
}

.filters-row {
  display: flex;
  gap: 0.8rem;
  flex-wrap: wrap;

  @media (min-width: $tabletMin) and (max-width: $tabletMid) {
    top: 8rem !important;
  }

  @media (max-width: $tabletMid) {
    background: white;
    flex-direction: column;
    position: absolute;
    left: 0;
    top: 4.8rem;
    z-index: 10;
    width: 100%;
    max-width: 21rem;
    border-radius: 0.8rem;
    padding: 1.1rem;
    margin-top: 0.8rem;
    box-shadow: 0 0 8px rgb(37 40 43 / 12%);

    * {
      width: 100%;
    }

    .two-datepicker {
      flex-wrap: wrap !important;
      flex-direction: column !important;
      gap: 0.8rem;

      .date-term-container__datepicker {
        width: auto;
      }

      .calendar-container {
        right: 4rem !important;
      }
    }
  }
}

@media (max-width: $tabletMid) {
  .ng-scroll-content {
    display: initial !important;
  }
}

.status-block {
  width: 1.3rem;
  height: 0.3rem;
  border-radius: 2px;
}

.td-status {
  width: 55px;
}

.custom-popup {
  .ngneat-dialog-backdrop {
    align-items: flex-start;
    overflow-y: scroll;

    .ngneat-dialog-content {
      margin-top: 15rem;
      padding: 2.4rem;
      overflow: unset;
      max-height: unset;
    }
  }
}

.cdk-global-overlay-wrapper {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(52, 64, 84, 0.7);
  display: flex;

  .cdk-overlay-pane {
    width: 100%;
    background-color: white;
    max-width: fit-content !important;
    border-radius: 1.2rem;
    margin-top: 13.5rem;
    margin-bottom: 13.5rem;
    max-height: 80% !important;
    overflow: auto;
  }
}

.gray {
  color: var(--app-color-gray-300);
}

.search-bar-map {
  position: relative;

  .search-container__input {
    background: white;

    &:hover {
      border: 1px solid var(--app-color-gray-50);
    }
    &:active {
      border: 1px solid var(--app-color-gray-50);
    }
    &:focus {
      border: 1px solid var(--app-color-gray-50);
    }

    & + .icon {
      position: absolute;
      left: 1.2rem;
    }
  }
}

.bold {
  font-weight: 500;
}

.bold-700 {
  font-weight: 700;
}
