@import 'adaptive';

.form-auth {
  @include screen(lg) {
    padding-left: 2.4rem;
    padding-right: 2.4rem;
  }

  .fields-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: var(--input-gap);

    @include screen(lg) {
      flex-direction: column;
    }

    .form__group {
      margin-top: 0;
      padding: 0;
      width: calc(50% - var(--input-gap) / 2);

      @include screen(lg) {
        width: 100%;
      }

      .hint {
        font-size: 1.4rem;
        line-height: 2rem;
        color: var(--app-color-gray-400);
      }

      &.wide {
        width: 100%;
      }

      &.hidden {
        display: none;
      }

      &.radio-buttons {
        margin-bottom: 1.6rem;

        .radio-group {
          display: flex;
          gap: 3.2rem;
        }
      }

      @include screen(xs) {
        width: 100%;
      }
    }
  }

  .form__subtitle {
    color: var(--app-color-gray-300);
    font-weight: 500;
    font-size: 1.6rem;
    text-transform: uppercase;
    margin-bottom: 1.6rem;
    margin-top: 2.4rem;
  }

  .heading {
    margin-bottom: 3.2rem;
  }

  .controls {
    margin-top: 3.2rem;
    display: flex;
    justify-content: flex-end;
    gap: var(--input-gap);

    .button {
      width: 100%;
    }

    @include screen(xs) {
      flex-direction: column;
    }
  }
}

.checkbox-container {
  padding-left: 2rem;
}

.red {
  --border-color: #e54141;
}

.mat-dialog-container .form-auth {
  padding-left: 0;
  padding-right: 0;
}
