@import 'variables';
@import 'adaptive';

.own-datepicker {
  .ngx-datepicker-container {
    .ngx-datepicker-input {
      width: 100%;
      background: none;
      border: none;
      cursor: pointer;
      border-radius: 1rem;
    }

    .calendar-container {
      width: 30rem !important;
      border: none !important;
      box-shadow: 0 1rem 3.2rem rgb(0 0 0 / 5%) !important;
      border-radius: 0.2rem !important;
      height: auto !important;
      background: #fff;

      @media (max-width: $tabletMid) {
        left: auto !important;
        right: 0 !important;
      }

      .top-container {
        padding: 1.5rem 2rem !important;
        justify-content: center !important;
      }

      .month-year-container {
        z-index: 2;
        width: 80%;
        text-align: center;

        .month-year-text {
          color: #141216;
          font-weight: 600;

          @media (max-width: $tabletMid) {
            margin-left: 40px;
            margin-right: 40px;
          }
        }
      }

      .controls {
        position: absolute;
        height: auto !important;
        width: 86%;
        justify-content: space-between !important;
        fill: var(--app-color-gray-900);
        flex-direction: row !important;

        .control {
          width: 10px;
        }

        .prev-month {
          margin-left: 0 !important;
        }
      }

      .topbar-container {
        padding: 2rem 0 1.7rem 2.3rem !important;
        position: relative !important;
        border-bottom: none !important;
        width: 14.9rem !important;

        svg {
          position: relative;
          width: 4.1rem;
          height: 100%;
          content: 'sdsd';

          g {
            display: none;
          }

          &:first-child {
            position: absolute;
            top: 0;
            right: -4.1rem;
            z-index: 1;
          }

          &:last-child {
            position: absolute;
            top: 0;
            right: -8.2rem;
          }
        }

        .topbar-title {
          width: 100% !important;
          font-family: var(--app-font-primary);
          font-style: normal;
          font-weight: 500 !important;
          font-size: 1.4rem !important;
          line-height: 1.7rem;
          position: relative;
          z-index: 0;
          text-transform: capitalize;

          &::before {
            content: url('../../assets/icons/month-arrow-left.svg');
            position: absolute;
            height: 100%;
            width: auto;
            right: -2.5rem;
          }

          &::after {
            content: url('../../assets/icons/month-arrow-right.svg');
            position: absolute;
            height: 100%;
            width: auto;
            right: -6.6rem;
          }
        }
      }

      .main-calendar-container {
        padding: 0 0.6rem 1.3rem !important;

        .main-calendar-day-names {
          padding: 0;

          .day-name-unit {
            text-transform: capitalize !important;
            color: var(--colorDatepickerDayName);
            font-size: var(--fontSizeDatepickerDay);
            font-weight: 500;
          }
        }

        .main-calendar-days {
          padding: 0 0.2rem !important;

          .day-unit {
            width: 4rem !important;
            height: 4rem !important;
            margin: 0.3rem 0 !important;
            font-size: var(--fontSizeDatepickerDay) !important;
            font-weight: 400;
            border: 0.1rem solid var(--app-color-white);
            border-radius: 50% !important;
            color: var(--app-color-gray-700);

            &:hover:not(.is-disabled) {
              border: 0.1rem solid var(--colorDatepickerDay) !important;
            }

            &.is-prev-month {
              color: var(--app-gray-primary-color-70);
            }

            &.is-today {
              background-color: var(--app-color-gray-100) !important;
              color: var(--app-color-gray-700) !important;
            }

            &.is-selected {
              background-color: var(--app-color-primary-600) !important;
              color: var(--colorDatepickerDaySelected) !important;
              font-weight: 500;
            }

            &.is-disabled {
              color: var(--app-gray-primary-color-70);
            }
          }
        }
      }

      .slimscroll-wrapper {
        height: 18.6rem !important;
        padding-bottom: 1.3rem !important;
        overflow: hidden !important;

        .main-calendar-years {
          overflow: hidden !important;
          height: 18.8rem !important;
          padding-right: 6px !important;

          .year-unit {
            width: 4.8rem !important;
            height: 4.8rem !important;
            margin: 0.2rem 0.9rem !important;
            font-size: 1.4rem !important;
            font-weight: 600;
            color: var(--colorDatepickerDay);
          }

          .year-unit:hover {
            border: 0.1rem solid var(--app-gray-primary-color-100);
            border-radius: 0.8rem !important;
          }

          .year-unit.is-selected {
            border: 0.1rem solid var(--colorDatepickerDay);
            border-radius: 0.8rem !important;
            font-size: 1.4rem !important;
            color: var(--colorDatepickerDay) !important;
          }
        }
      }
    }
  }
}

.own-datepicker datefrom {
  &.is-selected {
    border-bottom-left-radius: 1.8rem;
    border-top-left-radius: 1.8rem;
  }
}
