$break_xxs: 360px;
$break_xs: 556px;
$break_sm: 768px;
$break_md: 992px;
$break_lg: 1200px;
$break_xlg: 1440px;
$break-qhd: 2560px;
$break-xxlg: 3000px;
$break-xxxlg: 3440px;
$break-uhd: 3840px;
$screen-desktop-qhd: 'only screen and (min-width: #{$break-qhd})';
$screen-desktop-xxlg: 'only screen and (min-width: #{$break-xxlg})';
$screen-desktop-xxxlg: 'only screen and (min-width: #{$break-xxxlg})';
$screen-desktop-uhd: 'only screen and (min-width: #{$break-uhd})';

@mixin screen($list) {
  @if index($list, 'xlg') != null {
    @media screen and (max-width: $break_xlg) {
      @content;
    }
  }

  @if index($list, 'lg') != null {
    @media screen and (max-width: $break_lg) {
      @content;
    }
  }

  @if index($list, 'md') != null {
    @media screen and (max-width: $break_md) {
      @content;
    }
  }

  @if index($list, 'sm') != null {
    @media screen and (max-width: $break_sm) {
      @content;
    }
  }

  @if index($list, 'xs') != null {
    @media screen and (max-width: $break_xs) {
      @content;
    }
  }

  @if index($list, 'xxs') != null {
    @media screen and (max-width: $break_xxs) {
      @content;
    }
  }
}

@mixin width($width) {
  @media screen and (max-width: $width) {
    @content;
  }
}

@mixin height($height) {
  @media screen and (max-height: $height) {
    @content;
  }
}
