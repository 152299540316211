@import 'variables';

.native-input,
.native-input__outside {
  font-style: normal;
  font-weight: normal;
  box-sizing: border-box;
  background: var(--app-gray-primary-color-40);
  font-size: 1.6rem;
  line-height: 2.4rem;
  height: 5.4rem;
  width: 100%;
  background: var(--app-color-gray-50);
  border: 1px solid var(--app-color-gray-50);
  border-radius: 1rem;
  padding: 1.2rem;
  color: var(--app-color-gray-900);
  transition: border 0.3s;

  &__outside {
    box-shadow: inset 0 1px 2px rgb(0 0 0 / 3%);
    border-radius: 4px;
    border: unset;
    font-size: var(--fontXSmall);
    background: var(--backgroundInput);
    padding-left: 1.2rem;
    height: 4rem;

    &::placeholder {
      color: var(--selectColorPlaceholder);
    }
  }

  &.has-error {
    padding-right: 3.5rem;
  }

  &:focus,
  &:hover,
  &:active {
    border: 1px solid var(--app-color-primary-600);
  }

  &:disabled {
    background-color: var(--app-color-gray-100);
    border: 1px solid var(--app-color-gray-50);
  }

  &::placeholder {
    color: var(--app-color-gray-400);
  }

  &.no-disabled-styles {
    &:disabled {
      background-color: var(--app-color-gray-50);
    }
  }

  &.search-icon-padding {
    padding-left: 4.2rem;
    height: 4.4rem;
  }

  &.search-icon-padding + .icon {
    position: absolute;
    top: 1.1rem;
    left: 1.2rem;
  }

  &.white-background {
    background: white;
  }
}

.native-label,
.native-label__admin {
  font-style: normal;
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 2rem;
  color: var(--app-color-gray-700);
  margin-bottom: 1.2rem;

  &.required::after {
    content: ' *';
    color: red;
  }
}

.native-input.immutable {
  background-color: $backgroundColorContent;
}

.native-input.searchable {
  background: $backgroundColorInput calc(100% - 30px) 50% url('/assets/icons/v2/ic_search.svg') no-repeat;
  background-size: 16px 16px;
  padding-right: 50px;
}

.native-input.disable {
  background: var(--app-gray-on-primary-color);
}

.native-input[disabled] {
  //background: var(--app-gray-on-primary-color);
}

input[disabled],
.native-input.disable,
input.lock-field {
  cursor: not-allowed;
}

.ng-select-disabled,
.ng-select-disabled input,
.ng-select-disabled .ng-select-container,
.select-container.lock-field {
  cursor: not-allowed !important;
}

.native-text-area {
  height: 15rem;
  width: 100%;
  margin-top: 0.6rem;
  padding: 0.9rem 1.3rem;
  font-size: var(--fontXXXXXSmall);
  color: var(--mediumDarkGrey);
  transition: 0.5s;
  resize: none;
  background: var(--app-gray-primary-color-40);
  font-family: var(--app-font-primary);
  letter-spacing: $letterSpacing;
  border: 0.8px solid #e0e0e0;
  border-radius: 3.2px;

  &.filled {
    background: var(--app-color-white);
  }
}

.native-select {
  width: 100%;
  flex: 1;
  font-size: $fontXXXSmall;
  color: var(--textColorPrimary);
  transition: 0.5s;
  cursor: pointer;

  &__row {
    display: flex;
    flex-flow: column;
  }
}

@media screen and (max-width: 510px) {
  .native-select.to-long {
    .ng-value {
      max-width: 300px;
    }
  }
}

.native-input:focus,
.native-select:focus,
.native-input__outside:focus,
.native-text-area:focus {
  border: 0.8px solid var(--app-color-primary-300);
}

.native-input::placeholder,
.native-input__outside::placeholder,
.native-text-area::placeholder {
  color: $textColorPlaceholder;
  font-size: 1.6rem;
}

.error-word {
  display: none;
}

.error-field {
  &.native-select {
    margin-bottom: 30px !important;

    @media (max-width: 991px) {
      margin-bottom: 50px !important;
    }
  }

  .ng-select.simple.ng-select-single .ng-select-container {
    background-color: var(--app-color-error-50) !important;
    border: 1px solid var(--app-color-error-50) !important;
    border-color: var(--app-color-error-50) !important;
    transition: 0.5s !important;
  }

  .ng-select.simple.ng-select-multiple .ng-select-container {
    background-color: var(--app-color-error-50) !important;
    border: 1px solid var(--app-color-error-50) !important;
    border-color: var(--app-color-error-50) !important;
    transition: 0.5s !important;
  }

  .ng-select.sign-up .ng-select-container {
    background-color: var(--app-color-error-50) !important;
    border-bottom: 1px solid var(--app-color-error-50) !important;
    border-color: var(--app-color-error-50) !important;
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  &.select-container-multiple {
    height: 44px;
    margin-bottom: 30px !important;
  }

  &.native-select,
  &.select-container-multiple,
  input,
  textarea {
    background-color: var(--app-color-error-50) !important;
    border-color: var(--app-color-error-50);
    transition: 0.5s;
  }

  .ng-select .ng-select-container input {
    background-color: transparent !important;
  }

  .native-input__outside {
    border: 1px solid var(--app-color-error-50) !important;
    border-color: var(--app-color-error-50) !important;
    transition: 0.5s;
    background: #fff;
  }

  & > .error-word {
    font-style: normal;
    font-weight: normal;
    font-size: 1.4rem;
    line-height: 2rem;
    display: block;
    padding-top: 11px;
    padding-bottom: 7px;
    color: var(--app-color-error-500);
  }
}

.ng-select.simple.searchable-address.ng-select-single {
  width: 100%;
  margin-top: 0;
  height: 4.4rem;

  .ng-select-container {
    height: 4.4rem;
    padding-left: 3rem;
    background: $backgroundColorInput 1.2rem 50% url('/assets/icons/v2/ic_search-new.svg') no-repeat;
    background-size: 2.4rem 2.4rem;

    .ng-value-container .ng-input {
      padding-left: 3rem !important;
    }
  }

  .ng-arrow-wrapper {
    display: none;
  }

  .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
    height: 4.4rem;
    padding-top: 1.2rem;
  }
}
